import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LinearProgress } from '@mui/material';

import { RoutedContent } from 'src/routing/RoutedContent';

import {
    useAuthInterceptor,
    usePathInterceptor,
    useAppVersionInterceptor,
    useAppLanguageInterceptor,
} from 'src/shared/hooks';
import { useStore } from 'src/mst/StoreProvider';

const App: FC = (): JSX.Element => {
    const { user } = useStore();

    useEffect(() => {
        user.checkAuth();
    }, []);

    useAppVersionInterceptor();
    usePathInterceptor();
    useAuthInterceptor(user);
    useAppLanguageInterceptor();

    useEffect(() => {
        if (user.isAuth) {
            user.fetchUserData();
        }
    }, [user.isAuth]);

    if (user.isAuth === null) {
        return <LinearProgress color='secondary' />;
    }

    return (
        <>
            <RoutedContent />
            <ToastContainer position='bottom-right' />
        </>
    );
};

export default observer(App);
