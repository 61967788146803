import { useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useTranslation } from 'react-i18next';

import { useAxios } from 'src/lib/http-client/use-http-client';

export const useAppLanguageInterceptor = (): void => {
    const { i18n } = useTranslation();
    const axios = useAxios();

    const appVersionInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
        if (config.headers) {
            config.headers['Accept-Language'] = i18n.language;
        }

        return config;
    };

    const reqInterceptor = axios.interceptors.request.use(appVersionInterceptor);

    useEffect(() => {
        return () => {
            if (reqInterceptor) {
                axios.interceptors.request.eject(reqInterceptor);
            }
        };
    }, []);
};
