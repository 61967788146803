import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Message } from 'src/components/base/Message/Message';

import { APP_ROUTES } from 'src/routing/appRoutes';

const languageNamespaces = ['common'];

interface ProtectedRouteProps {
    path: string;
    isPrivate: boolean;
    authorized: boolean | null;
    userRoles: string[];
    roles?: string[] | null;
}

const ProtectedRoute = ({
    roles,
    userRoles,
    authorized,
    isPrivate,
}: ProtectedRouteProps): ReactElement | null => {
    if (!isPrivate) {
        return <Outlet />;
    }
    if (!authorized) {
        return <Navigate to={APP_ROUTES.LOGIN} />;
    }

    if (Array.isArray(roles) && Array.isArray(userRoles)) {
        const roleExist = roles.some((role) => userRoles.includes(role));
        if (!roleExist) {
            return (
                <Message
                    languageNamespaces={languageNamespaces}
                    message='common:noAccessToContent'
                />
            );
        }
    }

    return <Outlet />;
};
export default ProtectedRoute;
