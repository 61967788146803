import { useQuery, UseQueryResult } from 'react-query';
import qs from 'qs';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { StorageItemsResponse } from 'src/shared/types';

export const useStorages = (search?: string): UseQueryResult<StorageItemsResponse> => {
    const httpClient = useHttpClient();
    return useQuery(
        [`storage-items-${search}`],
        async () => {
            const queryParams: { filter?: string } = {};

            if (search) {
                queryParams.filter = search;
            }

            return httpClient.get(`storages?${qs.stringify(queryParams)}`);
        },
        { refetchOnWindowFocus: false }
    );
};
