import { useEffect, useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

type ResponseType = {
    label: string;
    value: string;
};

export const useGetAllFits = (
    subCategoryId: string,
    language: string
): ResponseType[] => {
    const [state, setState] = useState<ResponseType[]>([]);
    const httpClient = useHttpClient();

    const makeRequestToGetAllFits = (): Promise<ResponseType[]> => {
        return httpClient.get<ResponseType[]>(
            `item-subcategories/${subCategoryId}/item-fits`
        );
    };

    const updateFits = async (): Promise<void> => {
        const data: ResponseType[] = await makeRequestToGetAllFits();
        setState(data);
    };

    useEffect(() => {
        if (subCategoryId) {
            updateFits();
        } else {
            setState([]);
        }
    }, [subCategoryId, language]);

    return state;
};
