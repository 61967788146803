import { IStoreSnapshotOut } from './models';
import { DateTimeService } from 'src/lib/dateTimeService/dateTimeService';
import { itemsListFilters } from 'src/shared/constants';

export const imageDefaultValue = new Array(10).fill(null).map((_, index) => {
    return { id: '', key: 'additionalImage' + (1 + index), file: null, fileName: '' };
});
export const imagesTabDefaultValue = {
    id: null,
    previewFile: null,
    files: imageDefaultValue,
    dirty: false,
    filesIds: [],
    previewFileId: null,
    imagesLoaded: false,
};
export const commissionTabDefaultValue = {
    ownerId: '',
    ownerType: null,
    percentageBC: '',
    minimumPriceBC: '',
    bringingDate: new DateTimeService().getDateISOStringWithCurrentDay(),
    deadLine: null,
    commissionStatusId: '',
    dirty: false,
};
export const onlineShopTabDefaultValue = {
    online: 'no',
    shipping: 'no',
    dirty: false,
};
export const salesTabDefaultValue = {
    itemPriceGross: '',
    itemPriceWebshop: '',
    specialPrice: '',
    originalPrice: '',
    stock: '1',
    minimumStock: '',
    itemStatusId: '',
    vatRateId: 'c099940b-06d1-4aee-81b7-69a5f3b3c18c', // this id is taken from the back end. It is tha value for 0 value. It should be as a default value
    storageId: '',
    reservedTo: null,
    dirty: false,
};
export const descriptionTabDefaultValues = {
    itemNumber: '',
    name: '',
    tagIds: [],
    newGood: 'no',
    stateId: '',
    targetGroupId: '',
    brandId: '',
    categoryId: '',
    subCategoryId: '',
    articleTypeId: '',
    sizeId: '',
    aliasUSId: '',
    aliasUKId: '',
    aliasItlId: '',
    fitIds: [''],
    colorId: '',
    materialId: '',
    additionalInformation: '',
    subCategoryItems: [],
    brandItems: [],
    sizeItems: [],
    dirty: false,
};
export const defaultStoreState: IStoreSnapshotOut = {
    user: {
        id: '',
        name: '',
        surname: '',
        clientName: '',
        roles: [],
        isAuth: null,
        labelPrinters: [],
        email: '',
        defaultPercentageBC: null,
    },
    reservationStore: {
        selectedItem: null,
        _reservationItems: [],
    },
    saleStore: {
        _saleOrders: [],
        selectedOrder: null,
        sum: '0',
        total: '0',
        discountInPercent: '',
        discountInEuro: '',
        isSaleLoading: false,
        payment: 0,
        _vatGroups: [],
    },
    settlementListStore: {
        page: 0,
        rowsPerPage: 10,
        count: 0,
        sortField: null,
        sortDesc: null,
        searchValue: '',
        _settlementList: [],
    },
    settlementItemsListStore: {
        _items: [],
        _checkedItems: [],
        sortField: null,
        sortDesc: null,
        itemStatuses: ['all'],
        commissionStatuses: ['all'],
        selectedItem: null,
        ownerId: null,
        sum: 0,
    },
    maintainItems: {
        page: 0,
        sortField: null,
        sortDesc: null,
        rowsPerPage: 50,
        count: 0,
        searchValue: '',
        items: [],
        [itemsListFilters.subCategories]: ['all'],
        [itemsListFilters.categories]: ['all'],
        [itemsListFilters.storages]: ['all'],
        [itemsListFilters.statuses]: ['all'],
        [itemsListFilters.online]: ['all'],
        [itemsListFilters.shipping]: ['all'],
        [itemsListFilters.commissionStatuses]: ['all'],
    },
    userManagement: {
        searchValue: '',
        bringingCustomersStore: {
            page: 0,
            rowsPerPage: 10,
            sortField: null,
            sortDesc: null,
            isFetchDataLoading: false,
            count: 0,
            customers: [],
        },
        employeesStore: {
            page: 0,
            rowsPerPage: 10,
            sortField: null,
            sortDesc: null,
            isFetchDataLoading: false,
            count: 0,
            employees: [],
        },
    },
    navigation: {
        current: '',
    },
    addItemForm: {
        descriptionTab: descriptionTabDefaultValues,
        imagesTab: imagesTabDefaultValue,
        salesTab: salesTabDefaultValue,
        commissionTab: commissionTabDefaultValue,
        onlineShopTab: onlineShopTabDefaultValue,
        isUpdatingLoading: false,
    },
};
