import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
} from 'mobx-state-tree';
import { User } from './user';
import { MaintainItems } from './maintainItems';
import { Navigation } from './navigation';
import { UserManagement } from './userManagement';
import { AddItemForm } from './itemForm';
import { SaleStore } from './saleStore';
import { ReservationStore } from './reservationStore';
import { SettlementListStore } from './settlementListStore';
import { SettlementItemsListStore } from './settlementItemsListStore';

export const Store = types
    .model('Root', {
        user: User,
        maintainItems: MaintainItems,
        addItemForm: AddItemForm,
        navigation: Navigation,
        userManagement: UserManagement,
        saleStore: SaleStore,
        reservationStore: ReservationStore,
        settlementListStore: SettlementListStore,
        settlementItemsListStore: SettlementItemsListStore,
    })
    .actions((self) => {
        let initialState: IStoreSnapshotOut;

        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore() {
                applySnapshot(self, initialState);
            },
        };
    });

export type StoreModel = Instance<typeof Store>;
export type IStoreSnapshotOut = SnapshotOut<typeof Store>;
