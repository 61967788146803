import { types, SnapshotOut, applySnapshot, getSnapshot } from 'mobx-state-tree';

export const ReservationItem = types.model('ReservationItem', {
    id: types.identifier,
    itemNumber: types.optional(types.string, ''),
    name: types.maybeNull(types.optional(types.string, '')),
    storage: types.maybeNull(types.optional(types.string, '')),
    imageId: types.maybeNull(types.optional(types.string, '')),
    attachmentId: types.maybeNull(types.optional(types.string, '')),
});

export type IReservationItemSnapshotOut = SnapshotOut<typeof ReservationItem>;

export const ReservationStore = types
    .model('Reservation', {
        _reservationItems: types.optional(types.array(ReservationItem), []),
        selectedItem: types.maybeNull(types.reference(ReservationItem)),
    })
    .actions((self) => {
        let initialState: ReservationSnapshotStoreModel;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            removeById(id: string): void {
                applySnapshot(self, {
                    ...self,
                    selectedItem: null,
                    _reservationItems: self._reservationItems.filter(
                        (item) => item.id !== id
                    ),
                });
            },
            clearAll(): void {
                applySnapshot(self, {
                    ...self,
                    selectedItem: null,
                    _reservationItems: [],
                });
            },
            selectRow(id: string | null): void {
                applySnapshot(self, {
                    ...self,
                    selectedItem: id,
                });
            },
            addItem(item: IReservationItemSnapshotOut): void {
                const isItemAlreadyInBasket = self._reservationItems.some(
                    (order) => order.id === item.id
                );
                if (isItemAlreadyInBasket) {
                    return;
                }
                const selectedItem = self.selectedItem?.id || item.id;
                applySnapshot(self, {
                    ...self,
                    selectedItem,
                    _reservationItems: [...self._reservationItems, item],
                });
            },
            resetReservationStore(): void {
                applySnapshot(self, initialState);
            },
        };
    })
    .views((self) => {
        return {
            get reservationItems() {
                return [...self._reservationItems.map((item) => ({ ...item }))];
            },
            get reservationItemIds() {
                return self._reservationItems.map((item) => item.id);
            },
        };
    });

export type ReservationSnapshotStoreModel = SnapshotOut<typeof ReservationStore>;
