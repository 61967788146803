export const compressImage = (
    file: File | null,
    cb: (src: string | null, file: File | null) => void
): void => {
    if (!file) {
        return cb(null, null);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.addEventListener('load', () => {
        const imgElement = document.createElement('img');
        imgElement.src = reader.result as string;

        imgElement.addEventListener('load', (e: Event) => {
            const canvas = document.createElement('canvas');
            const maxWidth = 600;
            const scaleSize = maxWidth / (e.target as HTMLImageElement).width;
            canvas.width = maxWidth;
            canvas.height = (e.target as HTMLImageElement).height * scaleSize;

            const ctx = canvas.getContext('2d');

            ctx?.drawImage(
                e.target as CanvasImageSource,
                0,
                0,
                canvas.width,
                canvas.height
            );
            const srcEncoded = ctx?.canvas.toDataURL('image/jpeg');
            ctx?.canvas.toBlob(
                (blob) => {
                    if (blob) {
                        const newFile = new File([blob], file.name, {
                            type: 'image/jpeg',
                        });
                        if (srcEncoded && newFile) {
                            cb(srcEncoded, newFile);
                        }
                    }
                },
                'image/jpeg',
                0.9
            );
        });
    });
};
