import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface MessageProps {
    message: string;
    languageNamespaces: string[];
}

export const Message = ({ message, languageNamespaces }: MessageProps): ReactElement => {
    const { t } = useTranslation(languageNamespaces);
    return (
        <div
            style={{
                width: '100%',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {t(message)}
        </div>
    );
};

Message.defaultProps = {
    message: 'You have to provide message',
};
