import { createTheme } from '@mui/material/styles';

export enum THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#FFFFFF',
    PRIMARY_LIGHT_COLOR = '#FAFAFA',
    PRIMARY_DARK_COLOR = '#F4F4F4',
    PRIMARY_CONTRAST_TEXT_COLOR = '#3D3D3D',

    SECONDARY_MAIN_COLOR = '#50848A',
    SECONDARY_CONTRAST_TEXT_COLOR = '#FFFFFF',

    INFO_MAIN_COLOR = '#C9F2F7',
    INFO_LIGHT_COLOR = '#F3FEFF',
    INFO_DARK_COLOR = '#D2DCD8',
    INFO_CONTRAST_TEXT_COLOR = '#3D3D3D',

    ERROR_MAIN_COLOR = '#E53D3D',

    INPUT_BORDER_COLOR = 'rgba(0, 0, 0, 0.23)',
    SUBMIT_BUTTON_BG_COLOR = '#D69B92',
    INPUT_PLACEHOLDER_COLOR = '#7D7D7D',
    CHECKBOX_BORDER_COLOR = '#E3E3E3',
    MAINTAIN_ITEMS_SUBTITLE_TEXT_COLOR = '#757575',
    MAINTAIN_ITEMS_UPLOAD_IMAGE_BACKGROUND = '#E8FDFF',
    DELETE_ICON_BACKGROUND = '#DB4E4E',
    MAINTAIN_ITEMS_SELECTION_BORDER = 'MAINTAIN_ITEMS_SELECTION_BORDER',
    TOOLTIP_BACKGROUND = '#000000',
    MAINTAIN_ITEMS_SELECT_BACKGROUND = '#FEFEFE',
    MAINTAIN_ITEMS_SEARCHBAR_BACKGROUND = '#FCFCFC',
    MORE_ICON_DROPDOWN = '#447E9C',
    BORDER_COLOR = '#E3E3E3',
    FIELD_BORDER_COLOR = '#D6D6D6',
    TABLE_TITLE_COLORS = '#757575',
}

export const customTheme = createTheme({
    spacing: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1640,
        },
    },
    typography: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontSize: '4rem',
            fontWeight: 700,
            color: '#3D3D3D',
        },
        h2: {
            fontSize: '3.5rem',
            fontWeight: 700,
            color: '#3D3D3D',
        },
        h3: {
            fontSize: '3rem',
            fontWeight: 700,
            color: '#3D3D3D',
        },
        h4: {
            fontSize: '2.5rem',
            fontWeight: 700,
            color: '#3D3D3D',
        },
        h5: {
            fontSize: '2rem',
            fontWeight: 700,
            color: '#3D3D3D',
        },
        h6: {
            fontSize: '1.6rem',
            fontWeight: 700,
            color: '#3D3D3D',
        },
        body1: {
            fontSize: '1.8rem',
            fontWeight: 400,
        },
        body2: {
            fontSize: '1.6rem',
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: '1.3rem',
        },
        subtitle2: {
            fontSize: '1rem',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontSize: '10px',
                },
                body: {
                    minHeight: '100vh',
                    margin: '0px auto',
                },
                a: {
                    color: THEME_COLORS.SECONDARY_MAIN_COLOR,
                    textDecoration: 'none',
                    fontWeight: 400,
                    fontSize: '1.6rem',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
                'div,ul,tbody': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: `#888888 #595959`,
                    '&::-webkit-scrollbar': {
                        width: 12,
                        height: 6,
                    },
                    '&::-webkit-scrollbar-track': {
                        background: THEME_COLORS.PRIMARY_DARK_COLOR,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: THEME_COLORS.SECONDARY_MAIN_COLOR,
                    },
                },
                '& .MuiCalendarPicker-root': {
                    '& .MuiPickersDay-root': {
                        fontSize: '1.6rem',
                    },
                    '& .MuiTypography-root': {
                        fontSize: '1.6rem',
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    fontSize: '1.6rem',
                    fontWeight: 600,
                    textTransform: 'none',
                },
                outlined: {
                    borderWidth: 2,
                    '&:hover': {
                        borderWidth: 2,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    fontWeight: 700,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: THEME_COLORS.ERROR_MAIN_COLOR,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-root': {
                        width: 20,
                        height: 20,
                    },
                    '&:not(.Mui-checked)': {
                        '& .MuiSvgIcon-root': {
                            width: 20,
                            height: 20,
                            color: THEME_COLORS.BORDER_COLOR,
                        },
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            main: THEME_COLORS.PRIMARY_MAIN_COLOR,
            light: THEME_COLORS.PRIMARY_LIGHT_COLOR,
            dark: THEME_COLORS.PRIMARY_DARK_COLOR,
            contrastText: THEME_COLORS.PRIMARY_CONTRAST_TEXT_COLOR,
        },
        secondary: {
            main: THEME_COLORS.SECONDARY_MAIN_COLOR,
            contrastText: THEME_COLORS.SECONDARY_CONTRAST_TEXT_COLOR,
        },
        info: {
            main: THEME_COLORS.INFO_MAIN_COLOR,
            light: THEME_COLORS.INFO_LIGHT_COLOR,
            dark: THEME_COLORS.INFO_DARK_COLOR,
            contrastText: THEME_COLORS.INFO_CONTRAST_TEXT_COLOR,
        },
        error: {
            main: THEME_COLORS.ERROR_MAIN_COLOR,
        },
    },
});
