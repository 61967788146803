import { useEffect, useState } from 'react';
import qs from 'querystring';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { SelectItemType } from 'src/components/fields';

export type UseGetAllSizesType = {
    sizeItems: (SelectItemType & { itlSizeId: string })[];
    aliasUSItems: (SelectItemType & { itlSizeId: string })[];
    aliasUKItems: (SelectItemType & { itlSizeId: string })[];
    aliasItlItems: (SelectItemType & { itlSizeId: string })[];
};

type SizeItemResponse = { itlSizeId: string; label: string; value: string };

type ResponseType = {
    itlSizes: SizeItemResponse[];
    sizes: SizeItemResponse[];
    ukSizes: SizeItemResponse[];
    usSizes: SizeItemResponse[];
};

const prepareQueryParamsForRequest = (
    subCategoryId: string,
    targetGroupId: string
): string => {
    const queryParams: {
        ItemSubcategoryId?: string;
        TargetGroupId?: string;
    } = {};
    if (subCategoryId) {
        queryParams.ItemSubcategoryId = subCategoryId;
    }
    if (targetGroupId) {
        queryParams.TargetGroupId = targetGroupId;
    }
    return qs.stringify(queryParams);
};

export const useGetAllSizesRequest = (
    subCategoryId: string,
    targetGroupId: string
): UseGetAllSizesType => {
    const [state, setState] = useState<UseGetAllSizesType>({
        aliasItlItems: [],
        sizeItems: [],
        aliasUKItems: [],
        aliasUSItems: [],
    });
    const httpClient = useHttpClient();

    const queryString = prepareQueryParamsForRequest(subCategoryId, targetGroupId);

    const makeRequestToGetAllArticleType = (): Promise<ResponseType> => {
        return httpClient.get<ResponseType>(`item-sizes?${queryString}`);
    };

    const updateArticleType = async (): Promise<void> => {
        const data: ResponseType = await makeRequestToGetAllArticleType();
        const size = data.sizes.map((el) => {
            return {
                value: el.value,
                title: el.label,
                itlSizeId: el.itlSizeId,
            };
        });
        const aliasUK = data.ukSizes.map((el) => {
            return {
                value: el.value,
                title: el.label,
                itlSizeId: el.itlSizeId,
            };
        });
        const aliasUS = data.usSizes.map((el) => {
            return {
                value: el.value,
                title: el.label,
                itlSizeId: el.itlSizeId,
            };
        });
        const aliasItl = data.itlSizes.map((el) => {
            return {
                value: el.value,
                title: el.label,
                itlSizeId: el.itlSizeId,
            };
        });
        setState({
            aliasItlItems: aliasItl,
            sizeItems: size,
            aliasUKItems: aliasUK,
            aliasUSItems: aliasUS,
        });
    };

    useEffect(() => {
        updateArticleType();
    }, [subCategoryId, targetGroupId]);

    return state;
};
