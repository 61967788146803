import { types } from 'mobx-state-tree';
import { EmployeesStore } from './employeesStore';
import { BringingCustomersStore } from './bringingCustomersStore';

export const UserManagement = types
    .model('UserManagement', {
        searchValue: types.optional(types.string, ''),
        employeesStore: EmployeesStore,
        bringingCustomersStore: BringingCustomersStore,
    })
    .actions((self) => {
        return {
            setSearchValue(value: string): void {
                self.searchValue = value;
            },
        };
    });
