import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { baseUrl } from 'src/environment';

function configureAxiosGlobalSettings(): void {
    axios.defaults.baseURL = baseUrl;
}

export function initializeAxios(): AxiosInstance {
    configureAxiosGlobalSettings();

    const configuration: AxiosRequestConfig = {
        headers: {
            audienceId: 'eef4e1c2-d65d-4a3a-b0b9-f247af7cf2f9',
        },
    };

    configuration.baseURL = baseUrl;

    return axios.create(configuration);
}
