import { applySnapshot, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { sleep } from 'src/shared/utils';
import { Buffer } from 'buffer';
import { imageDefaultValue } from '../../defaultStoreState';

export type ImageType = {
    id: string | null;
    file: File | null;
};
interface IFetchImgByIdProps {
    allIds: string[];
    dataId: string | null;
    previewFileId?: string | null;
}

export const ImageModal = types.model('ImageModal', {
    id: types.maybeNull(types.string),
    key: types.maybeNull(types.string),
    file: types.maybeNull(types.frozen()),
    fileName: types.optional(types.string, ''),
});

export const AddItemImagesForm = types
    .model('addItemSalesForm', {
        id: types.maybeNull(types.string),
        filesIds: types.optional(types.maybeNull(types.array(types.string)), []),
        previewFileId: types.optional(types.maybeNull(types.string), null),
        previewFile: types.optional(types.maybeNull(ImageModal), null),
        files: types.optional(types.array(ImageModal), []),
        dirty: types.boolean,
        imagesLoaded: types.boolean,
    })
    .actions((self) => {
        return {
            setDirty(value: boolean) {
                self.dirty = value;
            },
            setImagesOnTheEmptyPositions(images: File[]): void {
                const newImgArr = [...self.files];
                let startEmptyPos = newImgArr.findIndex((image) => !image.file);
                if (startEmptyPos !== -1) {
                    images.forEach((file) => {
                        newImgArr[startEmptyPos] = {
                            file,
                            fileName: file.name,
                            id: '',
                            key: `additionalImage${startEmptyPos + 1}`,
                        };
                        startEmptyPos += 1;
                    });
                    applySnapshot(self, {
                        ...self,
                        files: newImgArr,
                    });
                }
            },
            deletePreview() {
                applySnapshot(self, {
                    ...self,
                    previewFile: {
                        id: 'previewImage',
                        key: 'previewImage',
                        file: null,
                        fileName: '',
                    },
                });
            },
            deleteImage(id: string, deleteById: boolean, defaultKey: string): void {
                const newFiles = self.files.map((item) => {
                    if (deleteById ? id === item.id : id === item.key) {
                        return {
                            id: '',
                            key: deleteById ? defaultKey : item.key,
                            file: null,
                            fileName: '',
                        };
                    } else {
                        return { ...item };
                    }
                });
                applySnapshot(self, { ...self, files: newFiles });
            },
            setImage({
                id,
                key,
                file,
                fileName = '',
            }: {
                id: string;
                key?: string;
                file: File | null;
                fileName?: string;
            }) {
                const image = {
                    id,
                    key: key ?? null,
                    file,
                    fileName,
                };

                if (key === 'previewImage') {
                    applySnapshot(self, { ...self, previewFile: image });
                } else {
                    const newImgArr = [...self.files];
                    const pos = newImgArr.findIndex((image) => image.key === key);
                    if (pos === -1) {
                        const currentPos = newImgArr.findIndex(
                            (image) => image.id === id
                        );
                        if (currentPos !== -1) {
                            newImgArr[currentPos] = image;
                        } else {
                            const emptyPos = newImgArr.findIndex((image) => !image.file);
                            newImgArr[emptyPos] = image;
                        }
                    } else {
                        newImgArr[pos] = image;
                    }

                    self.files = newImgArr as Instance<typeof self.files>;
                }
            },
        };
    })
    .actions((self) => {
        return {
            reset() {
                self.previewFile = null;
                self.files = imageDefaultValue as Instance<typeof self.files>;
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchImgById: flow(function* ({
                allIds,
                dataId,
                previewFileId,
            }: IFetchImgByIdProps) {
                self.reset();
                yield sleep(0);
                for (const id of allIds) {
                    yield sleep(0);

                    yield httpClient
                        .get(`item-images/${dataId}/attachments/${id}`, {
                            axiosConfig: {
                                headers: {
                                    'Content-Type': 'application/octet-stream',
                                },
                                responseType: 'arraybuffer',
                            },
                        })
                        .then((res: string) => {
                            const buffer = Buffer.from(res, 'binary');
                            return new File([buffer], 'filename', { type: 'image/png' });
                        })
                        .then((imageResponse: File) => {
                            if (id === previewFileId && imageResponse) {
                                self.setImage({
                                    id,
                                    key: 'previewImage',
                                    file: imageResponse,
                                    fileName: self.previewFile?.fileName,
                                });

                                return;
                            } else if (imageResponse) {
                                const fileName =
                                    self.files.find((el) => el.id === id)?.fileName ?? '';

                                self.setImage({
                                    id,
                                    file: imageResponse,
                                    fileName: fileName,
                                });
                            }
                        });
                }
            }),
        };
    })
    .actions((self) => {
        return {
            fetchImages: flow(function* () {
                if (!self.imagesLoaded) {
                    const allIds = [...(self?.filesIds || []), self.previewFileId].filter(
                        (el) => !!el
                    ) as string[];

                    yield self.fetchImgById({
                        allIds,
                        dataId: self.id,
                        previewFileId: self.previewFileId,
                    });
                    self.imagesLoaded = true;
                }
            }),
        };
    })
    .views((self) => {
        return {
            getFieldsValue() {
                return self;
            },
        };
    });
