import { addYears, addDays, formatISO } from 'date-fns';

export interface IDateTimeService {
    getDateISOStringWithoutTimeOffset(value: string | number): string | null;
}

export class DateTimeService implements IDateTimeService {
    public getDateISOStringWithoutTimeOffset(value: string | number): string | null {
        if (!value) {
            return null;
        }
        const formattedDate = formatISO(new Date(value), { representation: 'date' });
        return formattedDate;
    }
    public getNextDayDateISOString(): Date {
        const nextDayDate = addDays(new Date(), 1);
        return nextDayDate;
    }
    public getSumOfCurrentYearAndEnterYear(value: number): Date {
        const formattedDate = addYears(new Date(), value);
        return formattedDate;
    }
    public getDateISOStringWithCurrentDay(): string {
        const currentDate = formatISO(new Date());
        return currentDate;
    }
}
