import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useHandleResponseErrors = (
    errors?: string[],
    namespaces?: string[]
): string => {
    const { t } = useTranslation(namespaces);

    const errorMessage = useMemo(() => {
        if (Array.isArray(errors) && errors.length > 0) {
            return errors.reduce((acc, item, index) => {
                return index > 0
                    ? `${acc}. ${t(item, { ns: 'serverErrors' })}`
                    : t(item, { ns: 'serverErrors' });
            }, '');
        }
        return '';
    }, [errors, t]);

    return errorMessage;
};
