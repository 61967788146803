import { applySnapshot, flow, getEnv, types } from 'mobx-state-tree';
import { SortingRule } from 'react-table';
import qs from 'qs';

import { GetItemsQueryParams, MaintainItemsResponse } from 'src/shared/types';
import { HttpClient } from 'src/lib/http-client/http-client';

const Employee = types.model('Item', {
    id: types.string,
    employeeNumber: types.optional(types.string, ''),
    firstname: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    roles: types.optional(types.array(types.string), []),
    active: types.optional(types.boolean, false),
});

export const EmployeesStore = types
    .model('EmployeesStore', {
        employees: types.optional(types.array(Employee), []),
        page: types.optional(types.number, 0),
        rowsPerPage: types.optional(types.number, 0),
        sortField: types.maybeNull(types.string),
        sortDesc: types.maybeNull(types.boolean),
        isFetchDataLoading: types.boolean,
        count: types.number,
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            setSorting(sortBy?: SortingRule<string>) {
                if (sortBy) {
                    self.sortField = sortBy.id;
                    self.sortDesc = typeof sortBy.desc === 'boolean' ? sortBy.desc : null;
                } else {
                    self.sortField = null;
                    self.sortDesc = null;
                }
            },
            setPage(page: number) {
                self.page = page;
            },
            setRowsPerPage(rowsPerPage: number) {
                self.rowsPerPage = rowsPerPage;
            },
            fetchItems: flow(function* (params) {
                const [page, rowsPerPage, sortField, sortDesc, searchTerm] =
                    params.queryKey;

                const skip = (page + 1) * rowsPerPage - rowsPerPage;
                const take = rowsPerPage;
                const queryParams: GetItemsQueryParams = {
                    skip,
                    take,
                };
                if (searchTerm) {
                    queryParams.searchTerm = searchTerm;
                }
                if (sortField && typeof sortDesc === 'boolean') {
                    queryParams.sortField =
                        sortField[0].toUpperCase() + sortField.slice(1);
                    queryParams.isAscending = sortDesc;
                }
                try {
                    self.isFetchDataLoading = true;
                    const data = yield (
                        httpClient as HttpClient
                    ).get<MaintainItemsResponse>(
                        `users/internal?${qs.stringify(queryParams)}`
                    );
                    applySnapshot(self, {
                        ...self,
                        count: data.count,
                        employees: data.result,
                    });
                } catch {
                    applySnapshot(self, { ...self, employees: [], count: 0 });
                } finally {
                    self.isFetchDataLoading = false;
                }
            }),
        };
    })
    .views((self) => ({
        get getEmployees() {
            return [...self.employees];
        },
        fetchAllUserValues: (userId?: string) => {
            return self.employees.find((object) => object.id === userId);
        },
    }));
