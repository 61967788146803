import {
    types,
    SnapshotOut,
    IMSTArray,
    IArrayType,
    IOptionalIType,
    ISimpleType,
    IStateTreeNode,
    applySnapshot,
    getSnapshot,
    flow,
    getEnv,
    getRoot,
} from 'mobx-state-tree';
import { SortingRule } from 'react-table';
import qs from 'qs';

import {
    GetSettlementItemsByIdQueryParams,
    ConfirmSettlementBody,
} from 'src/shared/types';
import { CommissionStatuses } from 'src/shared/constants';
import { StoreModel } from '.';

export const SettlementItemsListItemStore = types
    .model('SettlementItemsListItemStore', {
        id: types.identifier,
        itemNumber: types.maybeNull(types.optional(types.string, '')),
        name: types.maybeNull(types.optional(types.string, '')),
        bringingDate: types.maybeNull(types.optional(types.string, '')),
        commissionStatus: types.maybeNull(types.optional(types.string, '')),
        commissionStatusId: types.maybeNull(types.optional(types.string, '')),
        status: types.maybeNull(types.optional(types.string, '')),
        statusId: types.maybeNull(types.optional(types.string, '')),
        percentageBC: types.maybeNull(types.optional(types.number, 0)),
        minimumPriceBC: types.maybeNull(types.optional(types.number, 0)),
        salesPrice: types.maybeNull(types.optional(types.number, 0)),
        calculatedShareBC: types.maybeNull(types.optional(types.number, 0)),
        shareBC: types.maybeNull(types.optional(types.number, 0)),
        notSelectable: types.maybeNull(types.optional(types.boolean, false)),
    })
    .actions((self) => {
        const store: StoreModel = getRoot(self);
        return {
            setShareBC(value: number): void {
                self.shareBC = value;
                store.settlementItemsListStore.calculateItems();
            },
        };
    });

export type SettlementItemsListItemSnapshotOut = SnapshotOut<
    typeof SettlementItemsListItemStore
>;

export const SettlementItemsListStore = types
    .model('SettlementItemsListStore', {
        _items: types.optional(types.array(SettlementItemsListItemStore), []),
        _checkedItems: types.optional(types.array(types.string), []),
        sortField: types.maybeNull(types.string),
        sortDesc: types.maybeNull(types.boolean),
        itemStatuses: types.optional(types.array(types.string), []),
        commissionStatuses: types.optional(types.array(types.string), []),
        selectedItem: types.maybeNull(types.reference(SettlementItemsListItemStore)),
        ownerId: types.maybeNull(types.string),
        sum: types.optional(types.number, 0),
    })
    .actions((self) => {
        let initialState: SettlementItemsListSnapshotOut;
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore() {
                applySnapshot(self, initialState);
            },
            setSorting(sortBy?: SortingRule<string>) {
                if (sortBy) {
                    self.sortField = sortBy.id;
                    self.sortDesc = typeof sortBy.desc === 'boolean' ? sortBy.desc : null;
                } else {
                    self.sortField = null;
                    self.sortDesc = null;
                }
            },
            setOwnerId(id: string) {
                self.ownerId = id;
            },
            setFilters(
                dataType: 'itemStatuses' | 'commissionStatuses',
                values: string[]
            ) {
                self[dataType] = [...values] as IMSTArray<ISimpleType<string>> &
                    IStateTreeNode<
                        IOptionalIType<IArrayType<ISimpleType<string>>, [undefined]>
                    >;
            },
            selectRow(id: string | null): void {
                applySnapshot(self, {
                    ...self,
                    selectedItem: id,
                });
            },
            setCheckedRows(ids: string[]): void {
                if (JSON.stringify([...self._checkedItems]) !== JSON.stringify(ids)) {
                    applySnapshot(self, {
                        ...self,
                        selectedItem: self.selectedItem?.id,
                        _checkedItems: ids,
                    });
                    this.calculateItems();
                }
            },
            fetchItems: flow(function* (params) {
                try {
                    const [
                        ownerId,
                        language,
                        sortField,
                        sortDesc,
                        itemStatuses,
                        commissionIds,
                    ] = params.queryKey;
                    if (ownerId) {
                        applySnapshot(self, {
                            ...self,
                            _checkedItems: [],
                            selectedItem: null,
                        });
                        const queryParams: GetSettlementItemsByIdQueryParams = {
                            language,
                        };
                        if (sortField && typeof sortDesc === 'boolean') {
                            queryParams.sortField =
                                sortField[0].toUpperCase() + sortField.slice(1);
                            queryParams.isAscending = sortDesc;
                        }
                        if (
                            Array.isArray(itemStatuses) &&
                            itemStatuses[0] !== 'all' &&
                            itemStatuses.length > 0
                        ) {
                            queryParams.itemStatusIds = itemStatuses;
                        }
                        if (
                            Array.isArray(commissionIds) &&
                            commissionIds[0] !== 'all' &&
                            commissionIds.length > 0
                        ) {
                            queryParams.commissionIds = commissionIds;
                        }
                        const data = yield httpClient.get(
                            `settlement/items/${ownerId}?${qs.stringify(queryParams)}`
                        );
                        applySnapshot(self, {
                            ...self,
                            _items: data.map(
                                (item: SettlementItemsListItemSnapshotOut) => ({
                                    ...item,
                                    shareBC:
                                        self._items.find(
                                            (selfItem) => selfItem.id === item.id
                                        )?.shareBC ||
                                        item.shareBC ||
                                        item.calculatedShareBC ||
                                        null,
                                    notSelectable:
                                        item.commissionStatusId ===
                                            CommissionStatuses.SETTLED ||
                                        item.commissionStatusId ===
                                            CommissionStatuses.RETURNED,
                                })
                            ),
                            _checkedItems: self._checkedItems || [],
                            selectedItem: null,
                            sum: self.sum || 0,
                        });
                    }
                } catch {
                    applySnapshot(self, {
                        ...self,
                        _items: [],
                        selectedItem: null,
                        _checkedItems: [],
                        sum: 0,
                    });
                }
            }),
            calculateItems: flow(function* () {
                try {
                    const shareBCValues = self._checkedItems
                        .filter((item) =>
                            self._items.some(
                                (j) =>
                                    j.id === item &&
                                    j.commissionStatusId !== CommissionStatuses.SETTLED
                            )
                        )
                        .map((item) => {
                            const itemData = self._items.find((j) => j.id === item);
                            if (itemData) {
                                return itemData.shareBC || 0;
                            }
                            return 0;
                        });
                    const result: { sum: number } = yield httpClient.post(
                        'settlement/calculate',
                        {
                            shareBC: shareBCValues,
                        }
                    );
                    self.sum = result.sum || 0;
                } catch {
                    self.sum = 0;
                }
            }),
            confirm: flow(function* () {
                const requestBody: ConfirmSettlementBody = {
                    settlementItems: self._checkedItems.map((itemId) => ({
                        itemId,
                        shareBC:
                            self._items.find((item) => item.id === itemId)?.shareBC || 0,
                    })),
                };
                yield httpClient.post('settlement/confirm', requestBody);
                applySnapshot(self, {
                    ...self,
                    _checkedItems: [],
                    selectedItem: null,
                    _items: [],
                });
            }),
        };
    })
    .views((self) => {
        return {
            get items() {
                return [...self._items.map((item) => ({ ...item }))];
            },
            getFilterValue(name: string) {
                switch (name) {
                    case 'itemStatuses': {
                        return [...self.itemStatuses];
                    }
                    case 'commissionStatuses': {
                        return [...self.commissionStatuses];
                    }
                    default: {
                        return [];
                    }
                }
            },
            get checkedItems() {
                return [...self._checkedItems];
            },
            get isConfirmationValid(): boolean {
                return Boolean(self._checkedItems.length);
            },
        };
    });

export type SettlementItemsListSnapshotOut = SnapshotOut<typeof SettlementItemsListStore>;
