/* eslint-disable @typescript-eslint/no-explicit-any */

export const buildFormData = (formData: FormData, data: any, parentKey: string): void => {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File) &&
        !(data instanceof Array)
    ) {
        Object.keys(data).forEach((key) => {
            buildFormData(
                formData,
                data[key],
                parentKey
                    ? `${capitalizeFirstLetter(parentKey)}.${capitalizeFirstLetter(key)}`
                    : key
            );
        });
    } else if (data || data === false) {
        if (data instanceof Array) {
            data.forEach((item) => {
                formData.append(
                    parentKey,
                    typeof item === 'object' && !(item instanceof File)
                        ? JSON.stringify(item)
                        : item
                );
            });
        } else {
            formData.append(parentKey, data);
        }
    }
};

function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
