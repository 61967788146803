import makeInspectable from 'mobx-devtools-mst';
import { AnyAbility } from '@casl/ability';
import { HttpClient } from 'src/lib/http-client/http-client';
import { StorageService } from 'src/lib/storageService';
import { isDevelopment } from 'src/environment';
import { IDateTimeService } from 'src/lib/dateTimeService/dateTimeService';
import { Store, StoreModel, IStoreSnapshotOut } from './models';

type StoreEnv = {
    ability: AnyAbility;
    httpClient: HttpClient;
    storageService: StorageService;
    dateTimeService: IDateTimeService;
};

export const createStore = (value: IStoreSnapshotOut, env: StoreEnv): StoreModel => {
    const store = Store.create(value, { env });
    if (isDevelopment) {
        makeInspectable(store);
    }
    return store;
};
