export enum APP_SECTIONS {
    MAINTAIN_ITEMS = 'maintainItems',
    CONFIGURATION = 'configuration',
    USER_MANAGEMENT = 'userManagement',
    REPORTING = 'reporting',
    SETTLEMENT_BC = 'settlementBC',
    CHECK_OUT = 'checkOut',
    SHIPPING = 'shipping',
    SALES = 'sales',
}
