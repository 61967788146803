import { Languages } from '../constants';

export const getCurrencySignDependOnLocale = (
    locale: string
): { prefix: string; suffix: string } => {
    switch (locale) {
        case Languages.DE: {
            return {
                prefix: '',
                suffix: ' €',
            };
        }
        case Languages.EN: {
            return {
                prefix: '€',
                suffix: '',
            };
        }
        default: {
            return {
                prefix: '',
                suffix: '',
            };
        }
    }
};
