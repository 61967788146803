import { ItemGetDto } from 'src/shared/types';
import { deleteEmtyItems } from 'src/pages/ItemPage/components/AddNewItem/onSubmitHandler';
import { IAddItemFormSnapshotIn } from '../addItemForm';
import { imageDefaultValue } from 'src/mst/defaultStoreState';

export const getToFormAdapter = (data: ItemGetDto): IAddItemFormSnapshotIn => {
    return {
        descriptionTab: {
            itemNumber: data.itemNumber,
            name: data.name ?? '',
            stateId: data.stateId ?? '',
            targetGroupId: data.targetGroupId ?? '',
            brandId: data.brandId ?? '',
            categoryId: data.categoryId ?? '',
            subCategoryId: data.subCategoryId ?? '',
            articleTypeId: data.itemTypeId ?? '',
            sizeId: data.sizeId ?? '',
            aliasUSId: data.usSizeId ?? '',
            aliasUKId: data.ukSizeId ?? '',
            aliasItlId: data.itlSizeId ?? '',
            colorId: data.colorId ?? '',
            materialId: data.materialId ?? '',
            additionalInformation: data.additionalInformation ?? '',
            tagIds: data.tagIds,
            newGood: data.isNewGood ? 'yes' : 'no',
            fitIds: deleteEmtyItems(data.fitIds),
            dirty: false,
        },
        salesTab: {
            itemPriceGross: data.sale?.itemPrice?.toString() ?? '',
            itemPriceWebshop: data.sale?.itemPriceWebshop?.toString() ?? '',
            specialPrice: data.sale?.salesPrice?.toString() ?? '',
            originalPrice: data.sale?.originalPrice?.toString() ?? '',
            stock: data.sale?.stock?.toString() ?? '',
            minimumStock: data.sale?.minimumStock?.toString() ?? '',
            itemStatusId: data.sale?.itemStatusId ?? '',
            vatRateId: data.sale?.vatRateId ?? '',
            storageId: data.sale?.storageId ?? '',
            reservedTo: data.sale?.reservedTo,
            dirty: false,
        },
        imagesTab: {
            dirty: false,
            id: data.images?.id,
            previewFileId: data.images?.previewImage?.id,
            filesIds: data.images?.images?.map((el) => el.id),
            files: imageDefaultValue,
            previewFile: data.images?.previewImage,
            imagesLoaded: false,
        },
        commissionTab: {
            commissionStatusId: data.commission?.commissionStatusId ?? '',
            bringingDate: data.commission?.bringingDate,
            deadLine: data.commission?.deadline,
            ownerId: data.commission?.ownerId ?? '',
            ownerType: data.commission?.ownerType ?? null,
            minimumPriceBC: data.commission?.minimumPriceBC?.toString() ?? '',
            percentageBC: data.commission?.percentageBC?.toString() ?? '',
            dirty: false,
        },
        onlineShopTab: {
            online:
                data.webshop?.online === null
                    ? null
                    : data.webshop?.online
                    ? 'yes'
                    : 'no',
            shipping:
                data.webshop?.shipping === null
                    ? null
                    : data.webshop?.shipping
                    ? 'yes'
                    : 'no',
            dirty: false,
        },
    };
};
