import { types, applySnapshot } from 'mobx-state-tree';

export type AddItemCommissionFormType = {
    ownerId: string;
    ownerType: string | null;
    commissionStatusId: string;
    percentageBC: string;
    minimumPriceBC: string;
    bringingDate: string | null;
    deadLine: string | null;
};

export const AddItemCommissionForm = types
    .model('addItemSalesForm', {
        ownerId: types.optional(types.string, ''),
        ownerType: types.maybeNull(types.optional(types.string, '')),
        percentageBC: types.optional(types.string, ''),
        commissionStatusId: types.optional(types.string, ''),
        minimumPriceBC: types.optional(types.string, ''),
        bringingDate: types.maybeNull(types.string),
        deadLine: types.maybeNull(types.string),
        dirty: types.boolean,
    })
    .actions((self) => {
        return {
            setDirty(value: boolean): void {
                self.dirty = value;
            },
            setOwnerData(id: string, type: string | null) {
                if (!self.ownerId) {
                    self.ownerId = id;
                }
                if (!self.ownerType) {
                    self.ownerType = type;
                }
            },
            setDefaultPercentageBC(value: string) {
                self.percentageBC = value;
            },
            setValues(values: AddItemCommissionFormType) {
                applySnapshot(self, { ...self, ...values, dirty: self.dirty });
            },
        };
    })
    .views((self) => {
        return {
            getFieldsValue() {
                return self;
            },
        };
    });
