import { AnyAbility, AbilityBuilder, Ability } from '@casl/ability';
import { ROLES } from 'src/shared/constants';
import { AbilityActions, AbilitySubjects } from './constants';

export const updateAbilityInstance = (ability: AnyAbility, roles: string[]): void => {
    if (!Array.isArray(roles) || !roles.length) {
        return;
    }

    const { can, rules } = new AbilityBuilder(Ability);

    if (roles.includes(ROLES.SYSTEM_ADMINISTRATOR)) {
        can(AbilityActions.use, AbilitySubjects.turnoverReservationReporting);
    }

    if (roles.includes(ROLES.EMPLOYEE)) {
        can(AbilityActions.see, [
            AbilitySubjects.items,
            AbilitySubjects.checkout,
            AbilitySubjects.reservation,
        ]);
        can(
            [AbilityActions.create, AbilityActions.edit, AbilityActions.delete],
            AbilitySubjects.item
        );
    }
    if (roles.includes(ROLES.CASHIER)) {
        can(AbilityActions.see, [
            AbilitySubjects.checkout,
            AbilitySubjects.sale,
            AbilitySubjects.reservation,
        ]);
    }
    if (roles.includes(ROLES.ONLINE_SHOP_MANAGER)) {
        can(AbilityActions.see, [AbilitySubjects.shipping]);
    }
    if (roles.includes(ROLES.SYSTEM_ADMINISTRATOR)) {
        can(
            [
                AbilityActions.create,
                AbilityActions.edit,
                AbilityActions.delete,
                AbilityActions.see,
            ],
            AbilitySubjects.user,
            {
                roles: {
                    $in: [ROLES.EMPLOYEE],
                },
            }
        );
        can([AbilityActions.see], AbilitySubjects.userManagement);
    }
    if (roles.includes(ROLES.STORE_OPERATOR)) {
        can(
            [AbilityActions.create, AbilityActions.edit, AbilityActions.delete],
            AbilitySubjects.user,
            {
                roles: {
                    $in: [
                        ROLES.EMPLOYEE,
                        ROLES.CASHIER,
                        ROLES.ONLINE_SHOP_MANAGER,
                        ROLES.STORE_OPERATOR,
                    ],
                },
            }
        );
        can(
            [AbilityActions.create, AbilityActions.edit, AbilityActions.delete],
            AbilitySubjects.bringinCustomer
        );
        can(AbilityActions.see, [
            AbilitySubjects.reporting,
            AbilitySubjects.settlementBC,
            AbilitySubjects.configuration,
            AbilitySubjects.bringinCustomer,
            AbilitySubjects.userManagement,
            AbilitySubjects.user,
        ]);
        can(AbilityActions.cancel, [AbilitySubjects.sale]);
    }

    ability.update(rules);
};
