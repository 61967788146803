import { IAddItemFormSnapshotOut } from '../addItemForm';
import { ItemPutDto } from 'src/shared/types';
import { DateTimeService } from 'src/lib/dateTimeService/dateTimeService';

export const formToPutAdapter = (
    data: IAddItemFormSnapshotOut,
    dateTimeService: DateTimeService
): ItemPutDto => {
    return {
        name: data.descriptionTab.name,
        stateId: data.descriptionTab.stateId,
        categoryId: data.descriptionTab.categoryId,
        subCategoryId: data.descriptionTab.subCategoryId,
        itemTypeId: data.descriptionTab.articleTypeId,
        fitIds: data.descriptionTab.fitIds.filter((item) => !!item),
        targetGroupId: data.descriptionTab.targetGroupId,
        brandId: data.descriptionTab.brandId,
        colorId: data.descriptionTab.colorId,
        sizeId: data.descriptionTab.sizeId,
        usSizeId: data.descriptionTab.aliasUSId,
        ukSizeId: data.descriptionTab.aliasUKId,
        itlSizeId: data.descriptionTab.aliasItlId,
        materialId: data.descriptionTab.materialId,
        tagIds: data.descriptionTab.tagIds.filter((item) => !!item),
        isNewGood: data.descriptionTab.newGood === 'yes',
        additionalInformation: data.descriptionTab.additionalInformation,
        webshop: {
            online:
                data.onlineShopTab.online === null
                    ? null
                    : data.onlineShopTab.online === 'yes',
            shipping:
                data.onlineShopTab.shipping === null
                    ? null
                    : data.onlineShopTab.shipping === 'yes',
        },
        sale: {
            itemPrice: data.salesTab.itemPriceGross
                ? +data.salesTab.itemPriceGross
                : null,
            itemPriceWebshop: data.salesTab.itemPriceWebshop
                ? +data.salesTab.itemPriceWebshop
                : null,
            salesPrice: data.salesTab.specialPrice
                ? Number(data.salesTab.specialPrice)
                : null,
            originalPrice: data.salesTab.originalPrice
                ? +data.salesTab.originalPrice
                : null,
            stock: data.salesTab.stock ? +data.salesTab.stock : null,
            minimumStock: data.salesTab.minimumStock ? +data.salesTab.minimumStock : null,
            reservedTo: data.salesTab.reservedTo
                ? dateTimeService.getDateISOStringWithoutTimeOffset(
                      data.salesTab.reservedTo
                  )
                : null,
            vatRateId: data.salesTab.vatRateId,
            storageId: data.salesTab.storageId,
        },
        images: {
            previewImageId:
                data.imagesTab.previewFile?.id &&
                data.imagesTab.previewFile?.id !== 'previewImage'
                    ? data.imagesTab.previewFile?.id
                    : null,
            newPreview: data.imagesTab.previewFile?.file,
            images: data.imagesTab.files
                .filter((el) => !!el.id)
                .map((el) => el.id) as string[],
            newImages: data.imagesTab.files.map((el) => el.file).filter((el) => !!el),
        },
        commission: {
            commissionStatusId: data.commissionTab.commissionStatusId,
            bringingDate: data.commissionTab.bringingDate
                ? dateTimeService.getDateISOStringWithoutTimeOffset(
                      data.commissionTab.bringingDate
                  )
                : null,
            ownerId: data.commissionTab.ownerId,
            ownerType: data.commissionTab.ownerType,
            deadline: data.commissionTab.deadLine
                ? dateTimeService.getDateISOStringWithoutTimeOffset(
                      data.commissionTab.deadLine
                  )
                : null,
            minimumPriceBC: Number(data.commissionTab.minimumPriceBC),
            percentageBC: Number(data.commissionTab.percentageBC),
        },
    };
};
