import {
    Instance,
    types,
    SnapshotOut,
    SnapshotIn,
    getSnapshot,
    applySnapshot,
    flow,
    getEnv,
} from 'mobx-state-tree';
import { AddItemDescriptionForm } from './addItemDescriptionForm';
import { AddItemSalesForm } from './addItemSalesForm';
import { AddItemImagesForm } from './addItemImagesForm';
import { AddItemCommissionForm } from './addItemCommissionForm';
import { AddItemOnlineShopForm } from './addItemOnlineShopForm';

import { APP_ROUTES } from 'src/routing/appRoutes';
import { history } from 'src/routing/history';
import { ItemGetDto, ItemPutDto } from 'src/shared/types';
import { buildFormData } from 'src/shared/utils';
import { getToFormAdapter, formToPutAdapter } from './adapters';

export const AddItemForm = types
    .model('AddItemForm', {
        descriptionTab: AddItemDescriptionForm,
        salesTab: AddItemSalesForm,
        imagesTab: AddItemImagesForm,
        commissionTab: AddItemCommissionForm,
        onlineShopTab: AddItemOnlineShopForm,
        isUpdatingLoading: types.maybeNull(types.boolean),
    })
    .actions((self) => {
        let initialState: IAddItemFormSnapshotOut;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            reset: () => {
                applySnapshot(self, initialState);
            },
            setDirtyForAllForms(value: boolean): void {
                self.commissionTab.dirty = value;
                self.descriptionTab.dirty = value;
                self.salesTab.dirty = value;
                self.imagesTab.dirty = value;
                self.onlineShopTab.dirty = value;
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchAllValues: flow(function* (itemId: string) {
                const data: ItemGetDto = yield httpClient.get(`items/${itemId}`);
                applySnapshot(self, { ...getToFormAdapter(data) });
                self.imagesTab.fetchImages();
            }),
        };
    })
    .actions((self) => {
        const {
            env: { httpClient, dateTimeService },
        } = getEnv(self);
        return {
            duplicateItem: flow(function* (itemId: string) {
                yield self.fetchAllValues(itemId);
                self.setDirtyForAllForms(true);
                self.descriptionTab.setItemNumber('');
                self.salesTab.setItemStatus('');
            }),
            updateValues: flow(function* (
                itemId: string,
                shouldClose: boolean,
                onSuccess?: () => void,
                settlementBCUserId?: string
            ) {
                self.isUpdatingLoading = true;

                const formattedBody: ItemPutDto = formToPutAdapter(
                    { ...self },
                    dateTimeService
                );

                const formData = new FormData();

                buildFormData(formData, formattedBody, '');

                try {
                    yield httpClient.put(`items/${itemId}`, formData);
                    yield self.fetchAllValues(itemId);
                    if (shouldClose) {
                        if (settlementBCUserId) {
                            history.back();
                        }
                        history.push(APP_ROUTES.MAINTAIN_ITEMS);
                    } else {
                        self.setDirtyForAllForms(false);
                    }
                    if (typeof onSuccess === 'function') {
                        onSuccess();
                    }
                } finally {
                    self.isUpdatingLoading = false;
                }
            }),
        };
    })
    .views((self) => {
        return {
            get dirty(): boolean {
                return (
                    self.descriptionTab.dirty ||
                    self.commissionTab.dirty ||
                    self.imagesTab.dirty ||
                    self.onlineShopTab.dirty ||
                    self.salesTab.dirty
                );
            },
        };
    });

export type AddItemFormModel = Instance<typeof AddItemForm>;
export type IAddItemFormSnapshotOut = SnapshotOut<typeof AddItemForm>;
export type IAddItemFormSnapshotIn = SnapshotIn<typeof AddItemForm>;
