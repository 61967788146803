export enum AbilitySubjects {
    app = 'app',
    userManagement = 'userManagement',
    user = 'user',
    bringinCustomer = 'bringinCustomer',
    reporting = 'reporting',
    settlementBC = 'settlementBC',
    configuration = 'configuration',
    items = 'items',
    item = 'item',
    checkout = 'checkout',
    sale = 'sale',
    reservation = 'reservation',
    shipping = 'shipping',
    turnoverReservationReporting = 'turnoverReservationReporting',
}
