import { useEffect, useState } from 'react';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

export type UseGetSelectionValuesResponseType = {
    label: string;
    value: string;
};

type ValueType =
    | 'ItemStatus'
    | 'CommissionStatus'
    | 'TargetGroup'
    | 'State'
    | 'Material'
    | 'Color'
    | 'Brand'
    | 'CatalogClothingCategory'
    | 'CatalogClothingSubcategory'
    | 'CatalogClothingItemType'
    | 'CatalogClothingFit';

export const useGetSelectionValues = (
    valueType: ValueType,
    language: string
): UseGetSelectionValuesResponseType[] => {
    const [state, setState] = useState<UseGetSelectionValuesResponseType[]>([]);
    const httpClient = useHttpClient();

    const makeRequestToGetAllMaterials = (): Promise<
        UseGetSelectionValuesResponseType[]
    > => {
        return httpClient.get<UseGetSelectionValuesResponseType[]>(
            `selection-values/${valueType}`
        );
    };

    const updateMaterials = async (): Promise<void> => {
        const data: UseGetSelectionValuesResponseType[] =
            await makeRequestToGetAllMaterials();
        setState(data);
    };

    useEffect(() => {
        updateMaterials();
    }, [language]);

    return state;
};
