import { useEffect, useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

type GetAllStoragesResponseType = {
    id: string;
    name: string;
    capacity: 0;
    location: string;
    room: string;
    shelf: string;
    level: string;
    compartment: string;
    box: string;
};

type AvailableDataType = {
    title: string;
    value: string;
};

export const useGetAllStorages = (language: string): AvailableDataType[] => {
    const [state, setState] = useState<AvailableDataType[]>([]);
    const httpClient = useHttpClient();

    const makeRequestToGetAllStorages = (): Promise<GetAllStoragesResponseType[]> => {
        return httpClient.get<GetAllStoragesResponseType[]>(`storages`);
    };

    const updateStorages = async (): Promise<void> => {
        const data: GetAllStoragesResponseType[] = await makeRequestToGetAllStorages();
        const availableData: AvailableDataType[] = data.map((el) => {
            return {
                title: el.name,
                value: el.id,
            };
        });
        setState(availableData);
    };

    useEffect(() => {
        updateStorages();
    }, [language]);

    return state;
};
