import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ConnectivityService } from '../connectivity-indicator/use-connectivity-service';
import { ComponentUnloadState } from '../custom-hooks/use-component-unload-state';
import { LoadingState } from '../custom-hooks/use-loading-state';
import { HttpRejectInfo } from './http-reject-info'; // data can be any
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

import i18n from 'src/i18-next.config';
import { collectionOfSpecifiedErrors } from 'src/shared/constants';

export interface HttpRequestOptions {
    isStandardErrorHandlingDisabled?: boolean;
    axiosConfig?: AxiosRequestConfig;
}

type ResolvePromise<T> = (value: T | PromiseLike<T>) => void;
type RejectPromise = (reason?: HttpRejectInfo) => void;
type AxiosCall<T> = () => Promise<AxiosResponse<T>>;

export interface HttpClient {
    readonly isPerformingRequest: boolean;

    get<T>(url: string, options?: HttpRequestOptions): Promise<T>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    post<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    put<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T>;

    delete<T>(url: string, options?: HttpRequestOptions): Promise<T>;

    download<T>(
        url: string,
        data?: T,
        onProgress?: (progress: number) => void
    ): Promise<void>;

    downloadExcel<T>(url: string, data?: T): Promise<void>;
}

export class AxiosHttpClient implements HttpClient {
    connectivityService?: ConnectivityService;

    constructor(
        private readonly axios: AxiosInstance,
        private readonly loadingState: LoadingState,
        private readonly componentUnloadState: ComponentUnloadState
    ) {}

    get isPerformingRequest(): boolean {
        return this.loadingState.isLoading;
    }

    get<T>(url: string, options?: HttpRequestOptions): Promise<T> {
        return this.tryPerformRequest(
            () => this.axios.get(url, options?.axiosConfig),
            options
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    post<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T> {
        return this.tryPerformRequest<T>(
            () => this.axios.post(url, data, options?.axiosConfig),
            options
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    put<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T> {
        return this.tryPerformRequest(
            () => this.axios.put(url, data, options?.axiosConfig),
            options
        );
    }

    delete<T>(url: string, options?: HttpRequestOptions): Promise<T> {
        return this.tryPerformRequest(
            () => this.axios.delete(url, options?.axiosConfig),
            options
        );
    }

    async download<T>(
        url: string,
        data?: T,
        onProgress?: (progress: number) => void
    ): Promise<void> {
        const response = await this.axios.get(url, {
            responseType: 'blob',
            onDownloadProgress: (e) => {
                if (onProgress && e && e.loaded && e.total) {
                    onProgress((e.loaded / e.total) * 100);
                }
            },
        });
        if (!response || !response.data || response.status !== 200) {
            return;
        }
        const contentDisposition = response.headers['content-disposition'];

        const filename = contentDisposition
            ?.split(';')
            ?.find((n) => n.includes('filename='))
            ?.replace('filename=', '')
            ?.replaceAll('"', '')
            ?.trim();

        saveAs(response.data, filename);
    }

    async downloadExcel<T>(url: string, data?: T): Promise<void> {
        const response = await this.axios.post(url, data, { responseType: 'blob' });
        if (!response || !response.data || response.status !== 200) {
            return;
        }
        const contentDisposition = response.headers['content-disposition'];

        const filename = contentDisposition
            ?.split(';')
            ?.find((n: string) => n.includes('filename='))
            ?.replace('filename=', '')
            ?.replaceAll('"', '')
            ?.trim();

        saveAs(response.data, filename);
    }

    private tryPerformRequest<T>(
        axiosCall: AxiosCall<T>,
        options: HttpRequestOptions | undefined
    ): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            // cant run request from 1 async action to  another async action mobx-state-tree
            // can make two requests in order
            // if (!this.trySetLoadingState()) {
            //     reject(HttpRejectInfo.otherRequestIsBeingExecuted());
            //     return;
            // }

            axiosCall()
                .then((response) => {
                    this.handleResponse(response, resolve);
                })
                .catch((error: AxiosError) => {
                    this.handleError(
                        error,
                        !!options?.isStandardErrorHandlingDisabled,
                        reject
                    );
                })
                .finally(() => this.resetLoadingState());
        });
    }

    private handleResponse<T>(
        response: AxiosResponse<T>,
        resolve: ResolvePromise<T>
    ): void {
        this.connectivityService?.reportConnectionSuccess();
        resolve(response?.data);
    }

    private handleError(
        error: AxiosError,
        isStandardErrorHandlingDisabled: boolean,
        reject: RejectPromise
    ): void {
        if (isStandardErrorHandlingDisabled) reject(HttpRejectInfo.notHandled(error));

        if (
            this.tryHandleErrorResponse(error, reject) ||
            this.tryHandleMissingResponse(error, reject)
        ) {
            return;
        }

        this.handleErroneousRequest(error, reject);
    }

    private tryHandleErrorResponse(error: AxiosError, reject: RejectPromise): boolean {
        const response = error.response;
        if (!response) return false;

        const flattedErrors: string[] = Object.values(
            response?.data?.errors || []
        ).flat() as string[];

        const specifiedError = flattedErrors.find((err: string) =>
            collectionOfSpecifiedErrors.some((item: string) => err.includes(item))
        );

        // If it is not a specified error then show a pop up message

        if (!specifiedError) {
            toast.error(
                i18n.t(flattedErrors[0] || 'unknownError', { ns: 'serverErrors' })
            );
        }

        if (response.status === 500) {
            reject(HttpRejectInfo.handled500InternalServerError(error));
            toast.error(i18n.t('unknownError', { ns: 'serverErrors' }));
        } else if (response.status === 404) {
            reject(HttpRejectInfo.handled404NotFound(error));
        } else {
            reject(HttpRejectInfo.notHandled(error));
        }

        this.connectivityService?.reportConnectionSuccess();
        return true;
    }

    private tryHandleMissingResponse(error: AxiosError, reject: RejectPromise): boolean {
        const request = error.request;
        if (!request) {
            return false;
        }

        this.connectivityService?.reportConnectionError();
        reject(HttpRejectInfo.handledServerNotReachable(error));
        return true;
    }

    private handleErroneousRequest(error: AxiosError, reject: RejectPromise): void {
        // eslint-disable-next-line no-console
        console.error(typeof error.toJSON === 'function' ? error.toJSON() : error);

        // TODO: notify errors

        reject(HttpRejectInfo.handledErroneousRequest(error));
    }

    private trySetLoadingState(): boolean {
        if (this.loadingState.isLoading) {
            return false;
        }

        this.loadingState.isLoading = true;
        return true;
    }

    private resetLoadingState(): void {
        this.loadingState.isLoading = false;
    }
}
