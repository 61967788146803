import {
    flow,
    getEnv,
    IArrayType,
    IMSTArray,
    Instance,
    IOptionalIType,
    ISimpleType,
    IStateTreeNode,
    types,
    getRoot,
    applySnapshot,
    SnapshotOut,
} from 'mobx-state-tree';
import { generatePath } from 'react-router-dom';
import { AuthResponse, ResponseError, UserDataResponse } from 'src/shared/types';
import { baseUrl, applicationVersion } from 'src/environment';
import { APP_ROUTES } from 'src/routing/appRoutes';
import { updateAbilityInstance } from 'src/roleAccesses';
import {
    CheckoutSubnavigationItems,
    ROLES,
    UserManagementSubnavigationItems,
} from 'src/shared/constants';
import { StoreModel } from './root';

export const ClientLabelPrinter = types.model('clientLabelPrinter', {
    id: types.string,
    name: types.string,
    ip: types.string,
    port: types.number,
    isDefault: types.boolean,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientLabelPrinterModel extends SnapshotOut<typeof ClientLabelPrinter> {}

export const User = types
    .model('User', {
        id: types.optional(types.string, ''),
        name: types.optional(types.string, ''),
        surname: types.optional(types.string, ''),
        clientName: types.optional(types.string, ''),
        defaultPercentageBC: types.maybeNull(types.number),
        email: types.optional(types.string, ''),
        roles: types.optional(types.array(types.string), []),
        labelPrinters: types.optional(types.array(ClientLabelPrinter), []),
        isAuth: types.maybeNull(types.boolean),
    })
    .actions((self) => {
        const {
            env: { ability },
        } = getEnv(self);
        return {
            setUserData(userData: UserDataResponse): void {
                applySnapshot(self, {
                    ...self,
                    labelPrinters: userData.labelPrinters,
                    id: userData.id,
                    email: userData.email,
                    clientName: userData.clientName ?? '-',
                    name: userData.firstname,
                    surname: userData.name,
                    defaultPercentageBC: userData.client.defaultPercentageBC,
                    roles: userData.roles as IMSTArray<ISimpleType<string>> &
                        IStateTreeNode<
                            IOptionalIType<IArrayType<ISimpleType<string>>, [undefined]>
                        >,
                });

                updateAbilityInstance(ability, userData.roles);
            },
            setAuth(isAuth: boolean): void {
                self.isAuth = isAuth;
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient, storageService },
        } = getEnv(self);
        return {
            fetchUserData: flow(function* () {
                const data: UserDataResponse = yield httpClient.get('users/current');
                self.setUserData(data);
            }),
            login: flow(function* (email: string, password: string) {
                const data: AuthResponse = yield httpClient.post('users/login', {
                    email,
                    password,
                });
                storageService.setTokens(data.accessToken, data.refreshToken);
                self.setAuth(true);
            }),
            logout: flow(function* () {
                try {
                    yield httpClient.post('users/logout');
                    self.setAuth(false);
                    self.setUserData({} as UserDataResponse);
                    const rootNode = getRoot(self) as StoreModel;
                    rootNode.resetStore();
                    storageService.clear();
                    applySnapshot(self, {
                        id: '',
                        isAuth: false,
                        roles: [],
                        name: '',
                        email: '',
                    });
                } catch (error) {
                    Promise.reject(error);
                }
            }),
            checkAuth: flow(function* () {
                const refreshToken = storageService.getItem('refreshToken');
                if (refreshToken) {
                    try {
                        const data: AuthResponse = yield httpClient.post(
                            `${baseUrl}/api/users/refresh-token`,
                            { refreshToken },
                            {
                                axiosConfig: {
                                    headers: { AppVersion: applicationVersion },
                                },
                            }
                        );
                        storageService.setTokens(data.accessToken, data.refreshToken);
                        self.setAuth(true);
                    } catch (response) {
                        const refreshTokenErrors: string[] = (response as ResponseError)
                            ?.error?.response?.data?.errors?.refreshToken;
                        if (
                            Array.isArray(refreshTokenErrors) &&
                            refreshTokenErrors?.length > 0 &&
                            refreshTokenErrors?.some(
                                (item) =>
                                    item ===
                                        "Refresh token doesn't match the saved token" ||
                                    item ===
                                        'Refresh token has expired, user needs to relogin' ||
                                    item === 'Refresh token has been revoked.' ||
                                    item === "Refresh token doesn't exist." ||
                                    item === 'The specified refresh token is invalid.'
                            )
                        ) {
                            storageService.removeTokens();
                            self.setAuth(false);
                            self.setUserData({} as UserDataResponse);
                        }
                    }
                } else {
                    self.setAuth(false);
                    self.setUserData({} as UserDataResponse);
                }
            }),
        };
    })
    .views((self) => {
        const roles = self.roles;
        return {
            get homePath(): string {
                if (!Array.isArray(roles) || roles.length === 0) {
                    return APP_ROUTES.LOGIN;
                }
                if (roles.includes(ROLES.EMPLOYEE)) {
                    return APP_ROUTES.MAINTAIN_ITEMS;
                }
                if (roles.includes(ROLES.STORE_OPERATOR)) {
                    return APP_ROUTES.CONFIGURATION;
                }
                if (roles.includes(ROLES.CASHIER)) {
                    return generatePath(APP_ROUTES.CHECKOUT_SECTION, {
                        tab: CheckoutSubnavigationItems.SALE,
                    });
                }
                if (roles.includes(ROLES.ONLINE_SHOP_MANAGER)) {
                    return APP_ROUTES.SHIPPING;
                }
                if (roles.includes(ROLES.SYSTEM_ADMINISTRATOR)) {
                    return generatePath(APP_ROUTES.USER_MANAGEMENT_SECTION, {
                        tab: UserManagementSubnavigationItems.EMPLOYEES,
                    });
                }
                return '';
            },
            get labelPrintersItems() {
                return [...self.labelPrinters.map((item) => ({ ...item }))];
            },
        };
    });

export type UserModel = Instance<typeof User>;
