import { types, applySnapshot } from 'mobx-state-tree';

export type AddItemOnlineShopFormType = {
    online: string | null;
    shipping: string | null;
};

export const AddItemOnlineShopForm = types
    .model('addItemSalesForm', {
        online: types.maybeNull(types.string),
        shipping: types.maybeNull(types.string),
        dirty: types.boolean,
    })
    .actions((self) => {
        return {
            setDirty(value: boolean): void {
                self.dirty = value;
            },
            setValues(values: AddItemOnlineShopFormType) {
                applySnapshot(self, { ...self, ...values, dirty: self.dirty });
            },
        };
    })
    .views((self) => {
        return {
            getFieldsValue() {
                return self;
            },
        };
    });
