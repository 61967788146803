import {
    IArrayType,
    IMSTArray,
    IOptionalIType,
    ISimpleType,
    IStateTreeNode,
    types,
    applySnapshot,
    flow,
    getEnv,
    SnapshotOut,
} from 'mobx-state-tree';
import { SortingRule } from 'react-table';
import qs from 'qs';

import { itemsListFilters } from 'src/shared/constants';
import {
    GetItemsQueryParams,
    ItemType,
    MaintainItemsResponse,
    StripeRefundStatus,
} from 'src/shared/types';
import { HttpClient } from 'src/lib/http-client/http-client';

const Item = types.model('Item', {
    id: types.string,
    name: types.optional(types.maybeNull(types.string), ''),
    itemNumber: types.optional(types.maybeNull(types.string), ''),
    category: types.optional(types.maybeNull(types.string), ''),
    categotyId: types.optional(types.maybeNull(types.string), ''),
    storage: types.optional(types.maybeNull(types.string), ''),
    storageId: types.optional(types.maybeNull(types.string), ''),
    status: types.optional(types.maybeNull(types.string), ''),
    online: types.optional(types.maybeNull(types.string), ''),
    shipping: types.optional(types.maybeNull(types.string), 'false'),
    statusId: types.optional(types.maybeNull(types.string), ''),
    subCategory: types.optional(types.maybeNull(types.string), ''),
    subcategoryId: types.optional(types.maybeNull(types.string), ''),
    bringCustomerNumber: types.optional(types.maybeNull(types.string), ''),
    commissionStatus: types.optional(types.maybeNull(types.string), ''),
    color: types.optional(types.maybeNull(types.string), ''),
    isRefundRequested: types.optional(types.maybeNull(types.boolean), false),
    orderId: types.optional(types.maybeNull(types.string), ''),
});

export type MaintainItemsSnapshotStoreModel = SnapshotOut<typeof MaintainItems>;

export const MaintainItems = types
    .model('MaintainItems', {
        [itemsListFilters.categories]: types.optional(types.array(types.string), []),
        [itemsListFilters.storages]: types.optional(types.array(types.string), []),
        [itemsListFilters.statuses]: types.optional(types.array(types.string), []),
        [itemsListFilters.online]: types.optional(types.array(types.string), []),
        [itemsListFilters.shipping]: types.optional(types.array(types.string), []),
        [itemsListFilters.subCategories]: types.optional(types.array(types.string), []),
        [itemsListFilters.commissionStatuses]: types.optional(
            types.array(types.string),
            []
        ),
        page: types.optional(types.number, 0),
        rowsPerPage: types.optional(types.number, 0),
        items: types.optional(types.array(Item), []),
        searchValue: types.optional(types.string, ''),
        sortField: types.maybeNull(types.string),
        sortDesc: types.maybeNull(types.boolean),
        count: types.number,
    })
    .actions((self) => {
        return {
            setSearchValue(value: string): void {
                self.searchValue = value;
                self.page = 0;
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            setSorting(sortBy?: SortingRule<string>) {
                if (sortBy) {
                    self.sortField = sortBy.id;
                    self.sortDesc = typeof sortBy.desc === 'boolean' ? sortBy.desc : null;
                } else {
                    self.sortField = null;
                    self.sortDesc = null;
                }
            },
            setPage(page: number) {
                self.page = page;
            },
            setRowsPerPage(rowsPerPage: number) {
                self.rowsPerPage = rowsPerPage;
                self.page = 0;
            },
            setSortField(sortField: string) {
                self.sortField = sortField;
            },
            setFilters(dataType: itemsListFilters, values: string[]) {
                self.page = 0;
                self[dataType] = [...values] as IMSTArray<ISimpleType<string>> &
                    IStateTreeNode<
                        IOptionalIType<IArrayType<ISimpleType<string>>, [undefined]>
                    >;
            },
            deleteItems(id: string) {
                applySnapshot(self, {
                    ...self,
                    items: self.items.filter((el) => el.id !== id),
                });
            },
            fetchItems: flow(function* (params) {
                const [
                    page,
                    rowsPerPage,
                    sortField,
                    sortDesc,
                    globalSearchTerm,
                    statuses,
                    subCategories,
                    categories,
                    storages,
                    commissionStatuses,
                    onlineStatuses,
                    shipping,
                ] = params;

                const skip = (page + 1) * rowsPerPage - rowsPerPage;
                const take = rowsPerPage;
                const queryParams: GetItemsQueryParams = {
                    skip,
                    take,
                };

                if (
                    Array.isArray(statuses) &&
                    statuses[0] !== 'all' &&
                    statuses.length > 0
                ) {
                    queryParams.allowedStatuses = statuses;
                }

                if (
                    Array.isArray(subCategories) &&
                    subCategories[0] !== 'all' &&
                    subCategories.length > 0
                ) {
                    queryParams.allowedSubcategories = subCategories;
                }
                if (
                    Array.isArray(categories) &&
                    categories[0] !== 'all' &&
                    categories.length > 0
                ) {
                    queryParams.allowedCategories = categories;
                }
                if (
                    Array.isArray(storages) &&
                    storages[0] !== 'all' &&
                    storages.length > 0
                ) {
                    queryParams.allowedStorages = storages;
                }

                if (
                    Array.isArray(commissionStatuses) &&
                    commissionStatuses[0] !== 'all' &&
                    commissionStatuses.length > 0
                ) {
                    queryParams.allowedCommissionStatuses = commissionStatuses;
                }

                if (
                    Array.isArray(onlineStatuses) &&
                    onlineStatuses[0] !== 'all' &&
                    onlineStatuses.length > 0
                ) {
                    queryParams.allowedOnlineStatuses = onlineStatuses;
                }

                if (
                    Array.isArray(shipping) &&
                    shipping[0] !== 'all' &&
                    shipping.length > 0
                ) {
                    queryParams.shipping = shipping;
                }

                if (sortField && typeof sortDesc === 'boolean') {
                    queryParams.sortField =
                        sortField[0].toUpperCase() + sortField.slice(1);
                    queryParams.isAscending = sortDesc;
                }

                if (globalSearchTerm) {
                    queryParams.globalSearchTerm = globalSearchTerm;
                }

                try {
                    const data = yield (
                        httpClient as HttpClient
                    ).get<MaintainItemsResponse>(
                        `items/page?${qs.stringify(queryParams)}`
                    );
                    applySnapshot(self, {
                        ...self,
                        count: data.count,
                        items: data.result.map((item: ItemType) => {
                            return {
                                ...item,
                                isRefundRequested:
                                    item.refund?.status === StripeRefundStatus.requested,
                                orderId: item.refund?.orderId,
                                bringCustomerNumber:
                                    item.bringCustomer?.bringCustomerNumber || null,
                            };
                        }),
                    });
                } catch {
                    applySnapshot(self, { ...self, items: [], count: 0 });
                }
            }),
        };
    })
    .views((self) => {
        return {
            getFilterValue(name: string) {
                return [...self[name as keyof MaintainItemsSnapshotStoreModel]] || [];
            },
            get data() {
                return [...self.items];
            },
        };
    });
