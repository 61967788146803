import { ComponentType, lazy, LazyExoticComponent } from 'react';
import { ROLES } from 'src/shared/constants';
import { APP_ROUTES } from './appRoutes';

const LoginPage = lazy(() =>
    import('src/pages').then(({ LoginPage }) => ({
        default: LoginPage,
    }))
);

const ForgotPasswordPage = lazy(() =>
    import('src/pages').then(({ ForgotPasswordPage }) => ({
        default: ForgotPasswordPage,
    }))
);

const MaintainItemsPage = lazy(() =>
    import('src/pages').then(({ MaintainItemsPage }) => ({
        default: MaintainItemsPage,
    }))
);

const ConfigurationPage = lazy(() =>
    import('src/pages').then(({ ConfigurationPage }) => ({
        default: ConfigurationPage,
    }))
);

const UserManagementPage = lazy(() =>
    import('src/pages').then(({ UserManagementPage }) => ({
        default: UserManagementPage,
    }))
);

const CreatePasswordPage = lazy(() =>
    import('src/pages').then(({ CreatePasswordPage }) => ({
        default: CreatePasswordPage,
    }))
);

const MainLayout = lazy(() =>
    import('src/components/layouts').then(({ MainLayout }) => ({
        default: MainLayout,
    }))
);

const ReportingPage = lazy(() =>
    import('src/pages').then(({ ReportingPage }) => ({
        default: ReportingPage,
    }))
);

const SettlementPage = lazy(() =>
    import('src/pages').then(({ SettlementPage }) => ({
        default: SettlementPage,
    }))
);

const SettlementItemsPage = lazy(() =>
    import('src/pages').then(({ SettlementItemsPage }) => ({
        default: SettlementItemsPage,
    }))
);

const CheckOutPage = lazy(() =>
    import('src/pages').then(({ CheckOutPage }) => ({
        default: CheckOutPage,
    }))
);

const ShippingPage = lazy(() =>
    import('src/pages').then(({ ShippingPage }) => ({
        default: ShippingPage,
    }))
);

const ItemPage = lazy(() => import('src/pages/ItemPage/ItemPage'));

const NoRoutePage = lazy(() =>
    import('src/pages').then(({ NoRoutePage }) => ({
        default: NoRoutePage,
    }))
);

const AddNewUserPage = lazy(() =>
    import('src/pages/UserManagementPage/forms/AddUserForm').then(({ AddNewUser }) => ({
        default: AddNewUser,
    }))
);
const AddNewCustomerPage = lazy(() =>
    import('src/pages/UserManagementPage/forms/AddCustomersForm').then(
        ({ AddNewCustomer }) => ({
            default: AddNewCustomer,
        })
    )
);

const ChangePasswordPage = lazy(() =>
    import('src/pages').then(({ ChangePasswordPage }) => ({
        default: ChangePasswordPage,
    }))
);

const SalesPage = lazy(() =>
    import('src/pages').then(({ SalesPage }) => ({
        default: SalesPage,
    }))
);

export interface RouteItem {
    path: string;
    component: LazyExoticComponent<ComponentType>;
    roles?: string[];
    private?: boolean;
    children?: RouteItem[];
}

const routingConfig: RouteItem[] = [
    {
        component: LoginPage,
        path: APP_ROUTES.LOGIN,
    },
    {
        component: ForgotPasswordPage,
        path: APP_ROUTES.FORGOT_PASSWORD,
    },
    {
        component: CreatePasswordPage,
        path: APP_ROUTES.CREATE_PASSWORD,
    },
    {
        private: true,
        component: ChangePasswordPage,
        path: APP_ROUTES.CHANGE_PASSWORD,
    },
    {
        private: true,
        component: MainLayout,
        path: APP_ROUTES.ROOT,
        children: [
            {
                private: true,
                component: MaintainItemsPage,
                path: APP_ROUTES.MAINTAIN_ITEMS,
                roles: [ROLES.EMPLOYEE],
            },
            {
                private: true,
                component: ItemPage,
                path: APP_ROUTES.ADD_NEW_ITEM,
                roles: [ROLES.EMPLOYEE],
            },
            {
                private: true,
                component: ItemPage,
                path: APP_ROUTES.ADD_NEW_ITEM_SECTION,
                roles: [ROLES.EMPLOYEE],
            },
            {
                private: true,
                component: ItemPage,
                path: APP_ROUTES.MAINTAIN_ITEMS_ITEM_BY_ID,
                roles: [ROLES.EMPLOYEE],
            },
            {
                private: true,
                component: ConfigurationPage,
                path: APP_ROUTES.CONFIGURATION,
                roles: [ROLES.STORE_OPERATOR],
            },
            {
                private: true,
                component: UserManagementPage,
                path: APP_ROUTES.USER_MANAGEMENT,
                roles: [ROLES.STORE_OPERATOR, ROLES.SYSTEM_ADMINISTRATOR],
            },
            {
                private: true,
                component: AddNewUserPage,
                path: APP_ROUTES.USER_MANAGEMENT_ADD_NEW_USER,
                roles: [ROLES.STORE_OPERATOR, ROLES.SYSTEM_ADMINISTRATOR],
            },
            {
                private: true,
                component: AddNewCustomerPage,
                path: APP_ROUTES.USER_MANAGEMENT_ADD_NEW_CUSTOMER,
                roles: [ROLES.STORE_OPERATOR],
            },
            {
                private: true,
                component: UserManagementPage,
                path: APP_ROUTES.USER_MANAGEMENT_SECTION,
                roles: [ROLES.STORE_OPERATOR, ROLES.SYSTEM_ADMINISTRATOR],
            },
            {
                private: true,
                component: AddNewUserPage,
                path: APP_ROUTES.USER_MANAGEMENT_VIEW_USER,
                roles: [ROLES.STORE_OPERATOR, ROLES.SYSTEM_ADMINISTRATOR],
            },

            {
                private: true,
                component: AddNewCustomerPage,
                path: APP_ROUTES.USER_MANAGEMENT_VIEW_CUSTOMER,
                roles: [ROLES.STORE_OPERATOR],
            },

            {
                private: true,
                component: ReportingPage,
                path: APP_ROUTES.REPORTING,
                roles: [ROLES.STORE_OPERATOR],
            },
            {
                private: true,
                component: SettlementPage,
                path: APP_ROUTES.SETTLEMENT,
                roles: [ROLES.STORE_OPERATOR],
            },
            {
                private: true,
                component: SettlementItemsPage,
                path: APP_ROUTES.SETTLEMENT_ITEMS_BY_ID,
                roles: [ROLES.STORE_OPERATOR],
            },
            {
                private: true,
                component: CheckOutPage,
                path: APP_ROUTES.CHECKOUT,
                roles: [ROLES.EMPLOYEE, ROLES.CASHIER],
            },
            {
                private: true,
                component: CheckOutPage,
                path: APP_ROUTES.CHECKOUT_SECTION,
                roles: [ROLES.EMPLOYEE, ROLES.CASHIER],
            },
            {
                private: true,
                component: ShippingPage,
                path: APP_ROUTES.SHIPPING,
                roles: [ROLES.ONLINE_SHOP_MANAGER],
            },
            {
                private: true,
                component: SalesPage,
                path: APP_ROUTES.SALES,
                roles: [ROLES.STORE_OPERATOR],
            },
        ],
    },
    {
        path: '*',
        component: NoRoutePage,
    },
];

export default routingConfig;
