// Status ids is static and stored at the backend as uuid, That is why it is possible to use such values

export enum ItemStatuses {
    IN_SALE = '2320663c-7488-4258-bb08-d2ba998e3499',
    IN_PROGRESS = 'a8c48927-7088-4f59-acd9-779d944c6ae3',
    LOCKED = 'd1887d15-c448-47fc-97c1-404066ca406c',
    RESERVED = '6ef140a6-1d98-4b93-8181-d557b83a0c67',
    SOLD = 'e89b5be8-b34c-4ce1-b429-46e5fd65f61a',
    CLOSEDOUT = 'a0f6da51-7f2d-44ad-8d08-f2f20a468eec',
}

export const statusesColors = {
    [ItemStatuses.IN_SALE]: '#43D48F',
    [ItemStatuses.IN_PROGRESS]: '#4398D4',
    [ItemStatuses.LOCKED]: '#979797',
    [ItemStatuses.RESERVED]: '#E99737',
    [ItemStatuses.SOLD]: '#D44343',
    [ItemStatuses.CLOSEDOUT]: '#435BD4',
};
