import { useEffect, useState } from 'react';
import { useStore } from 'src/mst/StoreProvider';
import { ItemStatuses } from '../constants';

interface ResponseType {
    isClosedOut: boolean;
}

export const useCheckCloseOutStatus = (): ResponseType => {
    const [isClosedOut, setClosedOut] = useState<boolean>(false);
    const { addItemForm } = useStore();
    const itemStatusId = addItemForm.salesTab.itemStatusId;

    useEffect(() => {
        itemStatusId === ItemStatuses.CLOSEDOUT
            ? setClosedOut(true)
            : setClosedOut(false);
    }, [addItemForm.salesTab.itemStatusId]);

    return { isClosedOut };
};
