import { types, applySnapshot } from 'mobx-state-tree';

export type AddItemSalesFormType = {
    itemPriceGross: string;
    itemPriceWebshop: string;
    specialPrice: string;
    originalPrice: string;
    stock: string;
    minimumStock: string;
    itemStatusId: string;
    vatRateId: string;
    storageId: string;
    reservedTo: string | null;
};

export const AddItemSalesForm = types
    .model('addItemSalesForm', {
        itemPriceGross: types.optional(types.string, ''),
        itemPriceWebshop: types.optional(types.string, ''),
        specialPrice: types.optional(types.string, ''),
        originalPrice: types.optional(types.string, ''),
        stock: types.optional(types.string, ''),
        minimumStock: types.optional(types.string, ''),
        itemStatusId: types.optional(types.string, ''),
        vatRateId: types.optional(types.string, ''),
        storageId: types.optional(types.string, ''),
        reservedTo: types.maybeNull(types.string),
        dirty: types.boolean,
    })
    .actions((self) => {
        return {
            setDirty(value: boolean): void {
                self.dirty = value;
            },
            setItemStatus(value: string) {
                self.itemStatusId = value;
            },
            setValues(values: AddItemSalesFormType) {
                applySnapshot(self, { ...self, ...values, dirty: self.dirty });
            },
            addStock(value: string) {
                self.stock = (+self.stock + +value).toString();
            },
        };
    })
    .views((self) => {
        return {
            getFieldsValue() {
                return { ...self };
            },
        };
    });
