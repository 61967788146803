import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

export enum SELECTION_VALUE_OPTIONS {
    ITEM_STATUS = 'ItemStatus',
    COMMISSION_STATUS = 'CommissionStatus',
    TARGET_GROUP = 'TargetGroup',
    STATE = 'State',
    MATERIAL = 'Material',
    COLOR = 'Color',
    BRAND = 'Brand',
    CATALOG_CLOTHING_CATEGORY = 'CatalogClothingCategory',
    CATALOG_CLOTHING_SUBCATEGORY = 'CatalogClothingSubcategory',
    CATALOG_CLOTHING_ITEM_TYPE = 'CatalogClothingItemType',
    CATALOG_CLOTHING_FIT = 'CatalogClothingFit',
    COUNTRY = 'Country',
}

export const useSelectionValues = (
    type: SELECTION_VALUE_OPTIONS
): UseQueryResult<{ label: string; value: string }[]> => {
    const httpClient = useHttpClient();
    const { i18n } = useTranslation();
    return useQuery(
        [type, i18n.language],
        async () => {
            return httpClient.get(`selection-values/${type}`);
        },
        { refetchOnWindowFocus: false }
    );
};
