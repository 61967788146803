import { types, Instance } from 'mobx-state-tree';

export const Navigation = types
    .model('User', {
        current: types.optional(types.string, ''),
    })
    .actions((self) => {
        return {
            setCurrent(section: string): void {
                self.current = section;
            },
        };
    });

export type NavigationModel = Instance<typeof Navigation>;
