import React, { Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Routes } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import { useStore } from 'src/mst/StoreProvider';

import routingConfig from './routingConfig';
import ProtectedRoute from './ProtectedRoute';

export const RoutedContent = observer((): JSX.Element => {
    const store = useStore();
    const isAuth = store.user.isAuth;
    const userRoles = store.user.roles;

    return (
        <Suspense fallback={<LinearProgress />}>
            <Routes>
                {routingConfig.map((route) => {
                    const {
                        path,
                        children,
                        roles,
                        private: isPrivate,
                        component: Component,
                    } = route;
                    return (
                        <Route
                            key={path}
                            element={
                                <ProtectedRoute
                                    isPrivate={Boolean(isPrivate)}
                                    path={path}
                                    authorized={isAuth}
                                    userRoles={[...userRoles]}
                                    roles={roles}
                                />
                            }
                            {...route}
                        >
                            <Route path={path} element={<Component />}>
                                {Array.isArray(children) &&
                                    children.map((childRoute) => {
                                        const ChildComponent = childRoute.component;
                                        return (
                                            <Route
                                                key={childRoute.path}
                                                element={
                                                    <ProtectedRoute
                                                        isPrivate={Boolean(
                                                            childRoute.private
                                                        )}
                                                        path={childRoute.path}
                                                        authorized={isAuth}
                                                        userRoles={[...userRoles]}
                                                        roles={childRoute.roles}
                                                    />
                                                }
                                                {...childRoute}
                                            >
                                                <Route
                                                    path={path}
                                                    element={<ChildComponent />}
                                                />
                                            </Route>
                                        );
                                    })}
                            </Route>
                        </Route>
                    );
                })}
            </Routes>
        </Suspense>
    );
});
