import { useEffect, useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

export type GetAllVATRateResponseType = {
    id: string;
    value: 0;
};

type AvailableDataType = {
    title: string;
    value: string;
};

export const useGetAllVATRateRequest = (): AvailableDataType[] => {
    const [state, setState] = useState<AvailableDataType[]>([]);
    const httpClient = useHttpClient();

    const makeRequestToGetAllVATRates = (): Promise<GetAllVATRateResponseType[]> => {
        return httpClient.get<GetAllVATRateResponseType[]>(`vat-rates`);
    };

    const updateVATRate = async (): Promise<void> => {
        const data: GetAllVATRateResponseType[] = await makeRequestToGetAllVATRates();
        const availableData: AvailableDataType[] = data.map((el) => {
            return {
                title: el.value.toString(),
                value: el.id,
            };
        });
        setState(availableData);
    };

    useEffect(() => {
        updateVATRate();
    }, []);

    return state;
};
