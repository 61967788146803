import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

interface useNavigationReturn {
    navigate: (e: MouseEvent<unknown>, path: string) => void;
}

export const useNavigation = (): useNavigationReturn => {
    const reactRouterNavigate = useNavigate();

    const openInNewTab = (path: string): void => {
        const newWindow = window.open(path, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const navigate = (e: MouseEvent<unknown> | null, path: string): void => {
        if (e?.ctrlKey) {
            openInNewTab(path);
        } else {
            reactRouterNavigate(path);
        }
    };
    return {
        navigate,
    };
};
