import { Instance, types, applySnapshot } from 'mobx-state-tree';
import { AvailableDataType } from 'src/pages/ItemPage/components/AddNewItem/AddNewItemDescription/descriptionRequests';

export type AddItemDescriptionFormType = {
    itemNumber: string;
    name: string;
    newGood: string;
    tagIds: string[];
    stateId: string;
    targetGroupId: string;
    brandId: string;
    categoryId: string;
    subCategoryId: string;
    articleTypeId: string;
    sizeId: string;
    aliasUSId: string;
    aliasUKId: string;
    aliasItlId: string;
    fitIds: string[];
    colorId: string;
    materialId: string;
    additionalInformation: string;
};

export const DropDawnItem = types.model('dropDawnItem', {
    title: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
    disabled: types.maybe(types.boolean),
});
export const AvailableItems = types.model('availableItems', {
    title: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
});

export const AddItemDescriptionForm = types
    .model('AddItemDescriptionForm', {
        itemNumber: types.optional(types.string, ''),
        name: types.optional(types.string, ''),
        stateId: types.optional(types.string, ''),
        targetGroupId: types.optional(types.string, ''),
        brandId: types.optional(types.string, ''),
        categoryId: types.optional(types.string, ''),
        subCategoryId: types.optional(types.string, ''),
        articleTypeId: types.optional(types.string, ''),
        sizeId: types.optional(types.string, ''),
        aliasUSId: types.optional(types.string, ''),
        aliasUKId: types.optional(types.string, ''),
        aliasItlId: types.optional(types.string, ''),
        fitIds: types.optional(types.array(types.string), ['']),
        colorId: types.optional(types.string, ''),
        materialId: types.optional(types.string, ''),
        additionalInformation: types.optional(types.string, ''),
        tagIds: types.optional(types.array(types.string), ['']),
        newGood: types.optional(types.string, 'yes'),
        subCategoryItems: types.optional(types.array(AvailableItems), []),
        brandItems: types.optional(types.array(AvailableItems), []),
        sizeItems: types.optional(types.array(AvailableItems), []),
        dirty: types.optional(types.maybeNull(types.boolean), null),
    })
    .actions((self) => {
        return {
            setDirty(value: boolean): void {
                self.dirty = value;
            },
            setBrandItems(value: AvailableDataType[]) {
                self.brandItems = value as Instance<typeof self.brandItems>;
            },
            setItemNumber(itemNumber: string) {
                self.itemNumber = itemNumber;
            },

            setItemName(name: string) {
                self.name = name;
            },
            setSubCategoryItems(value: AvailableDataType[]) {
                self.subCategoryItems = value as Instance<typeof self.subCategoryItems>;
            },
            setSizeItems(value: AvailableDataType[]) {
                self.sizeItems = value as Instance<typeof self.sizeItems>;
            },
            setValues(values: AddItemDescriptionFormType) {
                applySnapshot(self, {
                    ...self,
                    ...values,
                    dirty: self.dirty,
                });
            },
        };
    })
    .views((self) => {
        return {
            getFieldsValue() {
                return self;
            },
        };
    });
