import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, LinearProgress } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';

import i18n from 'src/i18-next.config';
import { StoreProvider } from 'src/mst/StoreProvider';
import { ConnectivityIndicatorInfrastructure } from 'src/lib/connectivity-indicator/connectivity-indicator-infrastructure';
import { HttpInfrastructure } from 'src/lib/http-client/http-infrastructure';
import { customTheme } from 'src/theming/theme';
import { AbilityContextProvider } from './roleAccesses';

import App from './components/App/App';
import { history } from './routing/history';
import { CustomRouter } from './routing/CustomRouter';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            structuralSharing: false,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <CustomRouter history={history}>
                <ConnectivityIndicatorInfrastructure>
                    <HttpInfrastructure>
                        <I18nextProvider i18n={i18n}>
                            <StoreProvider>
                                <AbilityContextProvider>
                                    <ThemeProvider theme={customTheme}>
                                        <LocalizationProvider dateAdapter={DateAdapter}>
                                            <Suspense fallback={<LinearProgress />}>
                                                <CssBaseline />
                                                <App />
                                            </Suspense>
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </AbilityContextProvider>
                            </StoreProvider>
                        </I18nextProvider>
                    </HttpInfrastructure>
                </ConnectivityIndicatorInfrastructure>
            </CustomRouter>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
