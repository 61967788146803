import { useMutation, UseMutationResult } from 'react-query';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { buildFormData } from 'src/shared/utils';
import { ImageType } from 'src/mst/models';
import { AvailableDataType } from './AddNewItemDescription/descriptionRequests';
import { SelectItemType } from 'src/components/fields';
import { ItemResultDto } from '.';

type AllValuesType = {
    descriptionTab: {
        name?: string;
        newGood?: string;
        tagIds?: string[];
        stateId?: string;
        targetGroupId?: string;
        brandId?: string;
        categoryId?: string;
        subCategoryId?: string;
        articleTypeId?: string;
        sizeId?: string;
        aliasUSId?: string;
        aliasUKId?: string;
        aliasItlId?: string;
        fitIds?: string[];
        colorId?: string;
        materialId?: string;
        additionalInformation?: string;
        brandItems: AvailableDataType[];
        subCategoryItems: AvailableDataType[];
        sizeItems: SelectItemType[];
    };
    salesTab: {
        itemPriceGross?: string;
        itemPriceWebshop?: string;
        specialPrice?: string;
        originalPrice?: string;
        stock?: string;
        minimumStock?: string;
        itemStatusId?: string;
        vatRateId?: string;
        storageId?: string;
        reservedTo?: string | null;
    };
    commissionTab: {
        ownerId?: string;
        ownerType?: string | null;
        commissionStatusId?: string;
        percentageBC?: string;
        minimumPriceBC?: string;
        bringingDate?: string | null;
        deadLine?: string | null;
    };
    onlineShopTab: {
        online: string | null;
        shipping: string | null;
    };
    imagesTab: {
        previewFile: ImageType | null;
        files: ImageType[];
    };
};

type CorrectSendValueType = {
    item: {
        isNewGood?: boolean;
        name?: string;
        tagIds?: string[];
        stateId?: string;
        targetGroupId?: string;
        brandId?: string;
        categoryId?: string;
        subCategoryId?: string;
        itemTypeId?: string;
        sizeId?: string;
        usSizeId?: string;
        ukSizeId?: string;
        itlSizeId?: string;
        fitIds?: string[];
        colorId?: string;
        materialId?: string;
        additionalInformation?: string;
    };
    itemSale: {
        itemPrice?: number;
        itemPriceWebshop?: number;
        salesPrice?: number;
        originalPrice?: number;
        stock?: number;
        minimumStock?: number;
        vatRateId?: string;
        storageId?: string;
        reservedTo?: string | null;
    };
    itemCommission: {
        ownerId?: string;
        ownerType?: string | null;
        commissionStatusId?: string;
        percentageBC?: number;
        minimumPriceBC?: number;
        bringingDate?: string | null;
        deadLine?: string | null;
    };
    itemWebshop: {
        online: boolean | null;
        shipping: boolean | null;
    };
    itemImage: {
        previewFile: File | null;
        files: File[] | null;
    };
};
export const deleteEmtyItems = (arr: string[]): string[] => {
    return arr.filter((el) => !!el);
};
const convertIntoCorrectFormat = (allValues: AllValuesType): FormData => {
    const filteredFiles = allValues.imagesTab.files
        .map((el) => el.file)
        .filter((el) => !!el) as File[];

    const name = allValues.descriptionTab.name
        ? allValues.descriptionTab.name
        : [
              allValues.descriptionTab.subCategoryItems.find(
                  (el) => el.value === allValues.descriptionTab.subCategoryId
              )?.title,
              allValues.descriptionTab.brandItems.find(
                  (el) => el.value === allValues.descriptionTab.brandId
              )?.title,
              allValues.descriptionTab.sizeItems.find(
                  (el) => el.value === allValues.descriptionTab.sizeId
              )?.title,
          ]
              .filter((el) => !!el)
              .join('-');

    const formattedData: CorrectSendValueType = {
        item: {
            name: name,
            additionalInformation: allValues.descriptionTab.additionalInformation,
            colorId: allValues.descriptionTab.colorId,
            categoryId: allValues.descriptionTab.categoryId,
            stateId: allValues.descriptionTab.stateId,
            targetGroupId: allValues.descriptionTab.targetGroupId,
            isNewGood: allValues.descriptionTab.newGood === 'yes',
            brandId: allValues.descriptionTab.brandId,
            subCategoryId: allValues.descriptionTab.subCategoryId,
            itemTypeId: allValues.descriptionTab.articleTypeId,
            sizeId: allValues.descriptionTab.sizeId,
            usSizeId: allValues.descriptionTab.aliasUSId,
            ukSizeId: allValues.descriptionTab.aliasUKId,
            itlSizeId: allValues.descriptionTab.aliasItlId,
            fitIds: deleteEmtyItems(allValues.descriptionTab.fitIds ?? []),
            materialId: allValues.descriptionTab.materialId,
            tagIds: deleteEmtyItems(allValues.descriptionTab.tagIds ?? []),
        },
        itemCommission: {
            ownerId: allValues.commissionTab.ownerId,
            ownerType: allValues.commissionTab.ownerType,
            commissionStatusId: allValues.commissionTab.commissionStatusId,
            percentageBC: allValues.commissionTab.percentageBC
                ? +allValues.commissionTab.percentageBC
                : undefined,
            minimumPriceBC: allValues.commissionTab.minimumPriceBC
                ? +allValues.commissionTab.minimumPriceBC
                : undefined,
            bringingDate: allValues.commissionTab.bringingDate,
            deadLine: allValues.commissionTab.deadLine,
        },
        itemWebshop: {
            online:
                allValues.onlineShopTab.online === null
                    ? null
                    : allValues.onlineShopTab.online === 'yes',
            shipping:
                allValues.onlineShopTab.shipping === null
                    ? null
                    : allValues.onlineShopTab.shipping === 'yes',
        },
        itemSale: {
            itemPrice: allValues.salesTab.itemPriceGross
                ? +allValues.salesTab.itemPriceGross
                : undefined,
            itemPriceWebshop: allValues.salesTab.itemPriceWebshop
                ? +allValues.salesTab.itemPriceWebshop
                : undefined,
            salesPrice: allValues.salesTab.specialPrice
                ? +allValues.salesTab.specialPrice
                : undefined,
            originalPrice: allValues.salesTab.originalPrice
                ? +allValues.salesTab.originalPrice
                : undefined,
            stock: allValues.salesTab.stock ? +allValues.salesTab.stock : undefined,
            minimumStock: allValues.salesTab.minimumStock
                ? +allValues.salesTab.minimumStock
                : undefined,
            vatRateId: allValues.salesTab.vatRateId,
            storageId: allValues.salesTab.storageId,
            reservedTo: allValues.salesTab.reservedTo,
        },
        itemImage: {
            files: filteredFiles ?? [],
            previewFile: allValues.imagesTab.previewFile?.file ?? null,
        },
    };
    const formData = new FormData();
    buildFormData(formData, formattedData, '');
    return formData;
};
type TVariablesOnSumbitHandler = {
    data: AllValuesType;
    onSuccesCallback?: (result: ItemResultDto) => void;
};
export const useSubmitHandler = (): UseMutationResult<
    unknown,
    unknown,
    TVariablesOnSumbitHandler,
    unknown
> => {
    const httpClient = useHttpClient();
    return useMutation(
        async (value: TVariablesOnSumbitHandler) => {
            const { data, onSuccesCallback } = value;

            const formattedBody = convertIntoCorrectFormat(data);

            const response = await httpClient.post<ItemResultDto>(
                'items/item',
                formattedBody
            );
            if (onSuccesCallback) {
                onSuccesCallback(response);
            }
        },
        {
            onSuccess: () => {
                // navigate(APP_ROUTES.MAINTAIN_ITEMS);
                // addItemForm.reset();
            },
        }
    );
};
